import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { TdctUserPromoterService } from '../tdct-user-promoter/tdct-user-promoter.service';


@Component({
  selector: 'app-tdct-user-promoter-editor-form',
  templateUrl: './tdct-user-promoter-editor-form.component.html',
  styleUrls: ['./tdct-user-promoter-editor-form.component.scss'],
})
export class TdctUserPromoterEditorFormComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public promoter: TdctUserPromoterService,
    public admin: TdctAdminService
  ) { }

  ngOnInit(): void {
    this.setProfile();
  }

  ngOnDestroy(): void {
    this.resetProfile();
  }

  setProfile(): void {
    if (!this.promoter.$.facebook) this.promoter.$.facebook = "facebook.com/";
    if (!this.promoter.$.snapchat) this.promoter.$.snapchat = "snapchat.com/add/";
    if (!this.promoter.$.instagram) this.promoter.$.instagram = "instagram.com/"
  }

  resetProfile(): void {
    if (this.promoter.$.facebook === 'facebook.com/') this.promoter.$.facebook = "";
    if (this.promoter.$.snapchat === 'snapchat.com/add/') this.promoter.$.snapchat = "";
    if (this.promoter.$.instagram === 'instagram.com/') this.promoter.$.instagram = "";
  }

  submitFromBio(): void {
    this.promoter.$.bio = this.promoter.$.bio.slice(0, -1);
    this.submit();
  }

  async submit() {
    this.submitted = true;
    this.promoter.loading = true;
    this.resetProfile();
    await this.editUserPromoter();
    this.promoter.loading = false;
    this.promoter.editing = false;
  }

  async editUserPromoter() {
    try {
      await this.promoter.get(this.promoter.$.id);
      await this.promoter.doc.update({
        name: this.promoter.$.name || "Unnamed UserPromoter",
        email: this.promoter.$.email || "",
        bio: this.promoter.$.bio || "",
        facebook: this.promoter.$.facebook || "",
        instagram: this.promoter.$.instagram || "",
        snapchat: this.promoter.$.snapchat || ""
      });
    } catch (error) {
      this.auth.presentErrorAlert(error);
    }
  }
}