import { Component, OnInit,  } from '@angular/core';



@Component({
  selector: 'imp-privacy',
  templateUrl: './imp-privacy.component.html',
  styleUrls: ['./imp-privacy.component.scss'],
})
export class ImpPrivacyComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
