
import { Option } from './imp-option.model';
export const DefaultOption: Option = {
    id: '',
    name: '',
    description: '',
    image: 'assets/img/default-profile.png',
    userId: '',
    userEmail: '',
    userName: '',
    userPhoto: '',
    promoterId: '',
    promoterEmail: '',
    promoterName: '',
    promoterPhoto: '',
    promoterBio: '',
    eventId: '',
    eventName: '',
    eventImage: '',
    eventDescription: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    ticketsAvailable: undefined,
    settings: [],
    digits: []
};
export const TicketOption: Option = {
    id: '1',
    name: 'Ticket Option',
    description: 'This is an Event Ticket Option',
    image: 'assets/img/chicago-event.jpg',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    eventId: '1',
    eventName: 'Chicago',
    eventImage: 'assets/img/chicago-event.jpg',
    eventDescription: 'This isn\'t an event you want to miss',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    ticketsAvailable: undefined,
    settings: ['ticket'],
    digits: ['event']
};
export const TableOption: Option = {
    id: '2',
    name: 'Table Option',
    description: 'This is an Event Table Option',
    image: 'assets/img/chicago-event.jpg',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    eventId: '1',
    eventName: 'Chicago',
    maxTableAttendance: 1,
    eventImage: 'assets/img/chicago-event.jpg',
    eventDescription: 'This isn\'t an event you want to miss',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    settings: ['table'],
    digits: ['event']
};
export const MockOptions: Option[] = [TicketOption, TableOption];
