import { Table } from './imp-table.model';
export const PaidTable: Table = {
    id: '1',
    stripeCardToken: '1',
    name: 'Paid Table',
    description: 'Paid Table Description',
    image: 'assets/img/icon.png',
    link: 'https://imp-events.com/event/1',
    route: 'event/1',
    qrCodeText: 'assets/img/icon.png',
    country: '001',
    state: 'MI',
    city: 'Detroit',
    street: '1 Jefferson Ave',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    eventId: '2',
    eventName: 'Detroit Event',
    eventDescription: 'Motown Chronicles',
    eventImage: 'assets/img/eggnog.jpg',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: '2019-02-13',
    displayStartTimestamp: '2019-02-13',
    displayEndTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    unixStartTimestamp: 1550109118,
    unixEndTimestamp: 1550110118,
    price: 10,
    zipcode: '60007',
    amountPaid: 0,
    bottlesPurchased: 0,
    tags: []
};
export const CompTable: Table = {
    id: '2',
    stripeCardToken: '1',
    name: 'Complimentary Table',
    description: 'Complimentary Table Description',
    image: 'assets/img/icon.png',
    link: 'https://imp-events.com/event/2',
    route: 'event/2',
    qrCodeText: 'assets/img/icon.png',
    country: '001',
    state: 'MI',
    city: 'Detroit',
    street: '1 Jefferson Ave',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    eventId: '2',
    eventName: 'Detroit Event',
    eventDescription: 'Motown Chronicles',
    eventImage: 'assets/img/eggnog.jpg',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: '2019-02-13',
    displayStartTimestamp: '2019-02-13',
    displayEndTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    unixStartTimestamp: 1550109118,
    unixEndTimestamp: 1550110118,
    price: 10,
    zipcode: '60007',
    amountPaid: 0,
    bottlesPurchased: 0,
    tags: []
};
export const MockTables: Table[] = [PaidTable, CompTable];
export const DefaultTable: Table = {
    id: '',
    stripeCardToken: '1',
    name: '',
    description: '',
    qrCodeText: '',
    country: '',
    state: '',
    city: '',
    street: '',
    image: '',
    link: '',
    route: '',
    userId: '',
    userEmail: '',
    userName: '',
    userPhoto: '',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    displayStartTimestamp: '',
    displayEndTimestamp: '',
    unixTimestamp: 0,
    unixLastUpdateTimestamp: 0,
    unixStartTimestamp: 0,
    unixEndTimestamp: 0,
    price: 0,
    zipcode: '',
    amountPaid: 0,
    bottlesPurchased: 0,
    tags: [],
    survey: '',
    tablesAvailable: 0,
    optionId: '',
    temporary: true,
    stripeSessionId: '',
};
