import { Component, OnInit,  } from '@angular/core';



@Component({
  selector: 'imp-terms',
  templateUrl: './imp-terms.component.html',
  styleUrls: ['./imp-terms.component.scss'],
})
export class ImpTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
