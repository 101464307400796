import { Injectable } from '@angular/core';
import { Bottle } from './imp-bottle.model';
import { DefaultBottle, MockBottles } from './imp-bottle.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import * as moment from 'moment';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';
import { ImpOptionService } from '../imp-option/imp-option.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { ImpTableService } from '../imp-table/imp-table.service';

@Injectable({
  providedIn: 'root'
})
export class ImpBottleService {

  $: Bottle = DefaultBottle;
  s$: Bottle[] = MockBottles;
  all$: Bottle[] = [];
  sHidden$: Bottle[] = [];
  search: string = "";
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  doc: AngularFirestoreDocument<Bottle>;
  col: AngularFirestoreCollection<Bottle>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public table: ImpTableService,
    public option: ImpOptionService,
    public event: ImpEventService,
    public afs: AngularFirestore
  ) { }

  async get(id: string): Promise<Bottle> {
    let bottlePath = "bottles/" + id;
    this.doc = this.afs.doc<Bottle>(bottlePath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  set(bottle: Bottle): void {
    this.$ = bottle;
    this.viewing = true;
    this.$ = this.auth.momentCreatedExactly(this.$);
    this.$ = this.auth.momentLastUpdatedFromNow(this.$);
  }

  async load(): Promise<Bottle[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$ = this.s$.map(bottle => this.auth.momentCreatedFromNow(bottle));
      this.s$ = this.s$.map(bottle => this.auth.momentLastUpdatedFromNow(bottle));
      this.set(this.s$[0]);
      this.total = this.s$.length;
      console.log("Set Bottles", this.s$);
    }
    this.loading = false
    return this.s$;
  }

  async filter() {
    if (this.auth.viewingTableBottles) {
      this.col = await this.afs.collection<Bottle>('bottles', ref => ref
        .where('tableId', '==', this.table.$.id)
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    } else {
      this.col = await this.afs.collection<Bottle>('bottles', ref => ref
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    }
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length == 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      let interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    // let loadinBottles: Bottle[] = [];
    // if (this.all$.length == 1) {
    //   loadinBottles = this.all$.splice(0, 1);
    // } else if (this.all$.length == 2) {
    //   loadinBottles = this.all$.splice(0, 2);
    // } else { loadinBottles = this.all$.splice(0, 3); }
    // this.s$.push(...loadinBottles);
    this.s$ = this.all$.map((bottle: Bottle) => bottle);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleView(): void {
    this.clear();
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      this.set(this.$);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }

  async create(source: any) {
    this.$.id = this.afs.createId();
    this.$.stripeCardToken = source.id;
    this.$.name = `${this.option.$.name}`;
    this.$.description = `1 Bottle at ${this.event.$.name}`;
    this.$.image = this.option.$.image;
    this.$.link = `https://imp-events.com/bottle/${this.$.id}`;
    this.$.route = `bottle/${this.$.id}`;
    this.$.price = this.option.$.price;
    this.stampUser();
    this.stampPromoter();
    this.stampTime();
    this.stampEventOption();
    await this.createBottleDoc();
  }

  async createBottleDoc() {
    return await this.afs.doc(`bottles/${this.$.id}`).set(this.$);
  }

  stampTime() {
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = this.$.displayTimestamp;
    this.$.unixTimestamp = moment().unix();
    this.$.unixLastUpdateTimestamp = this.$.unixTimestamp;
  }

  stampUser() {
    this.$.userId = this.user.$.id;
    this.$.userEmail = this.user.$.email;
    this.$.userName = this.user.$.name;
    this.$.userPhoto = this.user.$.photo;
  }

  stampPromoter() {
    this.$.promoterId = this.option.$.promoterId;
    this.$.promoterEmail = this.option.$.promoterEmail;
    this.$.promoterName = this.option.$.promoterName;
    this.$.promoterPhoto = this.option.$.promoterPhoto;
    this.$.promoterBio = this.option.$.promoterBio;
  }

  stampEventOption() {
    this.$.eventId = this.event.$.id;
    this.$.eventName = this.event.$.name;
    this.$.eventImage = this.event.$.image;
    this.$.eventDescription = this.event.$.description;
    this.$.street = this.event.$.street;
    this.$.city = this.event.$.city;
    this.$.state = this.event.$.state;
    this.$.zipcode = this.event.$.zipcode;
    this.$.country = this.event.$.country;
  }
}