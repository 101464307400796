import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from './tdct-auth.service';


@Component({
  selector: 'app-tdct-auth',
  templateUrl: './tdct-auth.component.html',
  styleUrls: ['./tdct-auth.component.scss'],
})
export class TdctAuthComponent implements OnInit {

  constructor(
    public auth: TdctAuthService
  ) { }

  ngOnInit() {}

}
