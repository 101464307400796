import { Component, Input } from '@angular/core';
import { ModalController, PopoverController, AlertController, LoadingController } from '@ionic/angular';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { ImpEventOptionComponent } from './../imp-event-option/imp-event-option.component';
import { ClipboardService } from 'ngx-clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImpEventService } from '../imp-event.service'; // Import the service
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { ImpCityService } from '../../imp-city/imp-city.service';

@Component({
  selector: 'app-imp-event-detail',
  templateUrl: './imp-event-detail.component.html',
  styleUrls: ['./imp-event-detail.component.scss'],
})

@NgModule({
  declarations: [
    ImpEventDetailComponent,
    // other components...
  ],
  imports: [
    CommonModule,
    // other modules...
  ],
  // other configurations...
})

export class ImpEventDetailComponent {
  @Input() event$: any; // The event data is passed as an input property
  tables: any[] = []; // To store tables
  tickets: any[] = []; // To store tickets
  bottles: any[] = []; // To store bottles
  eventCity: string;
  eventState: string;
  currentUser: any;
  isAdminOrOwner: boolean = false;
  cityName: string | null = null;
    processingFee = 0.05;
    serviceFee = 0.0360;
    statusQuoFee = 1.20;     // $1.20
  constructor(
      private modalCtrl: ModalController,
      public auth: TdctAuthService,
      public popover: PopoverController,
      private clipboardService: ClipboardService,
      private eventService: ImpEventService, // Inject the event service
      private optionService: ImpOptionService, // Inject the option service
      private functions: AngularFireFunctions, // Inject AngularFireFunctions
      private alertCtrl: AlertController, // Inject AlertController
      private loadingCtrl: LoadingController,
      public afa: AngularFireAuth,
      private city: ImpCityService
  ) {}

  ngOnInit() {
    this.loadEventOptions(); // Load options on initialization
    this.getCurrentUser();
    this.getCityName();
  }

  async getCityName(){
    if (this.event$.city && this.event$.city.length > 0) {
        const city = await this.city.get(this.event$.city);
        if (city) {
            this.cityName = city.name;
        }
    } 
}

  async getCurrentUser() {
    this.currentUser = await this.afa.currentUser;
    if (this.currentUser) {
      this.checkAdminOrOwner();
    }
  }

  checkAdminOrOwner() {
    if (!this.event$ || !this.currentUser) return;

    const isOwner = this.event$.userId === this.currentUser.uid; 
    const isAdmin = this.currentUser.roles && this.currentUser.roles.includes('admin'); 
    
    this.isAdminOrOwner = isOwner || isAdmin;
  }

  // Method to close the modal
  close() {
    this.modalCtrl.dismiss();
  }

  // Method to log object properties to the console
  logObjectProperties(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        console.log(`${key}: ${obj[key]}`);
      }
    }
  }

  // Example usage: call this method to log event$ properties
  showEventDetails() {
    this.logObjectProperties(this.event$);
  }

  // This is loading all currently sold items. NOT event tickets available.
  async loadEventTablesAndTickets() {
    try {
      this.tables = await this.eventService.loadEventTables();
      this.tickets = await this.eventService.loadEventTickets();
    } catch (error) {
      console.error('Error loading tables and tickets:', error);
    }
  }

  // Method to load options and categorize them
  async loadEventOptions() {
    try {
      const options = await this.optionService.getOptionsForEvent(this.event$?.id);
      this.tickets = options.filter(option => option.settings.includes('ticket'));
      this.tables = options.filter(option => option.settings.includes('table'));
      this.bottles = options.filter(option => option.settings.includes('bottle'));
    } catch (error) {
      console.error('Error loading event options:', error);
    }
  }

  toggleEventOption(action: string) {
    this.auth.viewingEventOptionPopover = !this.auth.viewingEventOptionPopover;
  
    if (this.auth.viewingEventOptionPopover) {
        switch (action) {
            case 'manage': {
                this.event$.managing = true;
                this.event$.sharing = false;
                this.presentPopover();
                break;
            }
            case 'share': {
               this.event$.managing = false;
               this.event$.sharing = true;
               this.copyToClipboard();
               break;
             }
            default: {
                this.event$.managing = false;
                this.event$.sharing = false;
            }
        }
    }
  }

  copyToClipboard() {
    let shareLink = `https://imp-events.com/event/${this.event$.id}`;
    this.clipboardService.copy(shareLink);
    console.log(this.event$.name);
    alert('Event copied to clipboard! Let�s make this night unforgettable! Share the excitement and rally your crew!');
  }

  async presentPopover() {
    let shareLink = this.event$.name;
    this.eventService.currentEvent = this.event$;
    console.log('popover for ' + shareLink);
    // Assuming `this.event$` holds the current event data
    this.auth.optionControl = await this.popover.create({
      component: ImpEventOptionComponent,  // The component to load in the popover
      showBackdrop: false,
      animated: true,
      componentProps: {
        eventData: this.event$  // Pass the current event data to the popover component
      }
    });

    this.auth.optionControl.onDidDismiss().then(() => {
      this.auth.viewingEventOptionPopover = false;
    });

    return await this.auth.optionControl.present();
  }

  // Method to handle image click
  viewImage(imageUrl: string) {
    console.log('Image clicked:', imageUrl);
    // Implement logic to open image in modal or navigate to detailed view
  }

  // Method to start the ticket purchase process
  startTicketPurchase(option: any) {
    this.openTicketQuantityPopup(option); // Call the method to open the quantity selection popup
  }

  // Method to open a quantity selection popup for tickets
  async openTicketQuantityPopup(option: any) {
    // Validate the option object
    if (!option || typeof option.price === 'undefined') {
      console.error('Invalid ticket option passed to openTicketQuantityPopup:', option);
      window.alert('There was an issue with the selected ticket option. Please try again.');
      return;
    }

    const quantityAlert = await this.alertCtrl.create({
      header: 'Select Ticket Quantity',
      inputs: [
        {
          name: 'quantity',
          type: 'number',
          placeholder: 'Enter quantity',
          min: 1,
          max: 10,
          value: 1 // Default value
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Purchase cancelled');
          }
        }, {
          text: 'Buy',
          handler: (data) => {
            if (!data.quantity || isNaN(data.quantity) || data.quantity <= 0) {
              console.error('Invalid quantity selected:', data.quantity);
              window.alert('Please select a valid quantity.');
              return;
            }
            
            const quantity = data.quantity;
            const totalPrice = option.price * quantity;
            this.confirmTicketPurchase(option, quantity, totalPrice);
          }
        }
      ]
    });

    await quantityAlert.present();
  }

  // Method to confirm ticket purchase after quantity selection
  async confirmTicketPurchase(option: any, quantity: number, totalPrice: number) {
    // Validate the option object
    if (!option || typeof option.price === 'undefined') {
      console.error('Invalid ticket option passed to confirmTicketPurchase:', option);
      window.alert('There was an issue with the selected ticket option. Please try again.');
      return;
    }

    const confirmAlert = await this.alertCtrl.create({
      header: 'Confirm Purchase',
      message: `You are purchasing ${quantity} tickets for a total of $${totalPrice.toFixed(2)}. Do you want to proceed?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Purchase cancelled');
          }
        },
        {
          text: 'Buy',
          handler: () => {
            this.buyOption(option, 1, quantity); // Call buyOption with the selected quantity
          }
        }
      ]
    });

    await confirmAlert.present();
  }

  // Method to handle "BUY" button click with optionType parameter
  async buyOption(option: any, optionType: number, quantity: number = 1) {
    console.log('Buying option:', option, 'Option Type:', optionType, 'Quantity:', quantity);

    // Check if the option object is valid
    if (!option || typeof option.price === 'undefined') {
      console.error('Invalid option passed to buyOption:', option);
      window.alert('There was an issue with the selected option. Please try again.');
      return;
    }

    const loading = await this.loadingCtrl.create({
      message: 'Processing your purchase...',
      spinner: 'crescent', // Spinner style, you can choose 'bubbles', 'circles', etc.
      duration: 10000 // Optional: auto-hide after 10 seconds in case of error
    });

    await loading.present();
    
    try {
      let response;

      if (optionType === 1) {  // 1 for tickets
        if (option.price === 0) {
          // Handle free ticket checkout
          response = await this.functions.httpsCallable('checkoutFreeTicket')({
            ticket: JSON.stringify(option) // Pass the ticket details
          }).toPromise();

          console.log('Free ticket processed successfully:', response);
          this.showPurchaseSuccessAlert('Free Ticket');
        } else {
          // Handle paid ticket checkout
          response = await this.functions.httpsCallable('stripeCheckoutWithoutDbQueries')({
            cart: {
              quantity: quantity, // Use the selected quantity
              name: this.event$.name,
              price: option.price,
              serviceFee: this.calculateServiceFee(option.price * quantity), // Calculate service fee
              processingFee: this.calculateProcessingFee(option.price * quantity), // Calculate processing fee
              ticket: JSON.stringify(option) // Pass the ticket details
            }
          }).toPromise();

        console.log('Stripe session created:', response);
        // Redirect the user to Stripe's checkout page
        window.location.href = response.session.url;
        }

      } else if (optionType === 2) {  // 2 for tables
        response = await this.functions.httpsCallable('stripeCheckoutTableWithoutDbQueries')({
          cart: {
            tablePrice: option.price,
            totalPrice: option.price + this.calculateServiceFee(option.price) + this.calculateProcessingFee(option.price), // Total price including fees
            bottlePrice: option.bottlePrice || 0, // If your option includes bottle price
            serviceFee: this.calculateServiceFee(option.price),
            processingFee: this.calculateProcessingFee(option.price),
            name: this.event$.name,
            table: JSON.stringify(option) // Pass the table details
          }
        }).toPromise();

        console.log('Stripe session created:', response);
        // Redirect the user to Stripe's checkout page
        window.location.href = response.session.url;
      }
    } catch (error) {
      // Log detailed error information
      console.error('Error during purchase:', error);

      // Additional detailed logging
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
      console.error('Error details:', error.details || 'No additional details provided');

      // Check for Firebase function errors
      if (error.code) {
        console.error('Firebase error code:', error.code);
        console.error('Firebase error message:', error.message);
        console.error('Firebase error details:', error.details || 'No details available');
      }

      // Check if it's a standard JavaScript error
      if (error instanceof Error) {
        console.error('JavaScript error message:', error.message);
        console.error('JavaScript error stack:', error.stack);
      }

      // Check for network-related errors (if applicable)
      if (error.response) {
        console.error('Network error status:', error.response.status);
        console.error('Network error data:', error.response.data);
      }

      // Optionally, show error to the user
      window.alert('There was an issue processing your purchase. Please try again later.');
    } finally {
      await loading.dismiss(); // Dismiss the loading spinner
    }
  }

  // Utility methods to calculate fees
    calculateServiceFee(price: number): number {
        return Math.ceil((price * this.serviceFee + this.statusQuoFee) * 100) / 100;
  }

    calculateProcessingFee(price: number): number {
        return Math.ceil(price * this.processingFee * 100) / 100; 
  }

  // Method to show a success alert after purchase
  async showPurchaseSuccessAlert(itemType: string) {
    const successAlert = await this.alertCtrl.create({
      header: `${itemType} Purchase Successful`,
      message: `Your ${itemType.toLowerCase()} has been purchased successfully. You can access it under your profile!`,
      buttons: ['OK']
    });

    await successAlert.present();
  }
}
