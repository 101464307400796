import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-imp-option',
  templateUrl: './imp-option.component.html',
  styleUrls: ['./imp-option.component.scss'],
})
export class ImpOptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
