import { Component, OnInit,  } from '@angular/core';
import { Auth } from '../../tdct-auth/tdct-auth.model';
import { DefaultAuth } from '../../tdct-auth/tdct-auth.mocks';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AlertController } from '@ionic/angular';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-tdct-user-email-form',
  templateUrl: './tdct-user-email-form.component.html',
  styleUrls: ['./tdct-user-email-form.component.scss'],
})
export class TdctUserEmailFormComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public alert: AlertController
  ) { }

  ngOnInit() { }

  async submit(emailForm) {
    this.submitted = true;
    if (this.validEmail()) {
      await this.updateEmail(emailForm);
      this.auth.viewingUserEmailForm = false;
      this.clearForm(emailForm);
      this.displayEmailUpdateConfirmation();
    }
  }

  validEmail(): boolean {
    return true;
  }

  async updateEmail(emailForm) {
    this.user.$.email = emailForm.email;
  }

  async displayEmailUpdateConfirmation() {
    const alert = await this.alert.create({
      header: "Email Updated",
      message: 'Your email has been updated.',
      buttons: ['OK']
    });
    await alert.present();
  }

  clearForm(emailForm: Auth): void {
    emailForm.email = "";
    this.submitted = false;
  }

}