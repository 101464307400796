import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Table } from '../imp-table.model';

@Component({
  selector: 'app-imp-table-detail',
  templateUrl: './imp-table-detail.component.html',
  styleUrls: ['./imp-table-detail.component.scss'],
})
export class ImpTableDetailComponent {

  @Input() table: Table;

  constructor(private modalCtrl: ModalController) { }

  close() {
    this.modalCtrl.dismiss();
  }
}
