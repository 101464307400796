import { Component, OnInit, Input, OnChanges,  } from '@angular/core';
import { Promoter } from './tdct-user-promoter.model';
import { DefaultPromoter } from './tdct-user-promoter.mocks';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserPromoterService } from './tdct-user-promoter.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { PopoverController } from '@ionic/angular';
import { TdctUserPromoterOptionComponent } from '../tdct-user-promoter-option/tdct-user-promoter-option.component';
import { TdctUserService } from '../tdct-user.service';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-tdct-user-promoter',
  templateUrl: './tdct-user-promoter.component.html',
  styleUrls: ['./tdct-user-promoter.component.scss'],
})
export class TdctUserPromoterComponent implements OnInit, OnChanges {

  @Input() promoter$: Promoter = DefaultPromoter;
  followingPromoter: boolean = false;
  public userId: string;
  constructor(
    public theme: ImpThemeService,
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public admin: TdctAdminService,
    public popover: PopoverController,
    public afa: AngularFireAuth,
  ) { }

  ngOnInit() {
    this.afa.authState.subscribe((user) => {
      if (user) {
          this.userId = user.uid; 
        }
    });
  }

  ngOnChanges(): void {
    this.event.load( this.userId)
    this.markPromoterFollowing();
    if (this.auth.uploadingUserPromoterImage) {
      this.auth.uploadingUserPromoterImage = !this.auth.uploadingUserPromoterImage;
    }
  }

  markPromoterFollowing(): void {
    this.followingPromoter = this.promoter$.followers.includes(this.user.$.id);
  }

  async togglePromoterFollow() {
    this.promoter.loading = true;
    this.followingPromoter = !this.followingPromoter;
    if (this.followingPromoter) {
      this.promoter$.followers.push(this.user.$.id);
      ++this.promoter$.followerCount;
    } else {
      this.promoter$.followers = this.promoter$.followers.filter(userId => userId !== this.user.$.id);
      --this.promoter$.followerCount;
    }
    await this.promoter.afs.doc(`users/${this.promoter$.userId}/promoters/${this.promoter$.id}`).update(this.promoter$);
    this.promoter.loading = false;
  }

  togglePromoterEvents() {
    if (this.auth.viewingUserPromoterEventCreatorForm) {this.auth.viewingUserPromoterEventCreatorForm = false; }
    if (this.auth.viewingEventAnalytics) { this.auth.viewingEventAnalytics = false; } else {
      this.auth.viewingPromoterEvents = !this.auth.viewingPromoterEvents;
      if (this.auth.viewingPromoterEvents) {
        this.event.load(this.userId)
      }
    }
  }

  toggleUserPromoterImageChange() {
    const authId: string = this.auth.$.uid;
    const userId: string = this.user.$.id;
    const userPromoterId: string = this.user.$.userPromoterId;
    const selectedPromoterId: string = this.promoter$.id;
    const otherSelectedPromoterId: string = this.promoter.$.id;
    const canUse: boolean = ((authId === userId) && (userPromoterId === selectedPromoterId));
    if (canUse) {
      this.auth.uploadingUserPromoterImage = !this.auth.uploadingUserPromoterImage;
    }
  }

  toggleUserPromoterOption() {
    this.auth.viewingUserPromoterOptionPopover = !this.auth.viewingUserPromoterOptionPopover;
    if (this.auth.viewingUserPromoterOptionPopover) {
      this.promoter.set(this.promoter$);
      this.presentPopover();
    }
  }

  async presentPopover() {
    this.auth.optionControl = await this.popover.create({
      component: TdctUserPromoterOptionComponent,
      showBackdrop: false,
      animated: true
    });
    this.auth.optionControl.onDidDismiss().then(() => {
      this.auth.viewingUserPromoterOptionPopover = false;
    });
    return await this.auth.optionControl.present();
  }

  routeLogin() {
    this.auth.signOut();
    this.auth.clearBrandDigitStates();
    this.theme.tab.selected = 'moreTab';
    this.auth.viewing = true;
    this.auth.toggleLoginForm();
  }

  openSocial(link: string) {
    console.log('Opening Social', link);
    const fullLink = `https://${link}`;
    this.auth.open(fullLink);
  }
}
