import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-ask-editor-form',
  templateUrl: './tdct-ask-editor-form.component.html',
  styleUrls: ['./tdct-ask-editor-form.component.scss'],
})
export class TdctAskEditorFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
