import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { Chart } from "chart.js";
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { ImpTicketService } from '../../imp-ticket/imp-ticket.service';
import { ImpTableService } from '../../imp-table/imp-table.service';
import { ImpBottleService } from '../../imp-bottle/imp-bottle.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpEventService } from '../imp-event.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-imp-event-analytics',
    templateUrl: './imp-event-analytics.component.html',
    styleUrls: ['./imp-event-analytics.component.scss'],
})
export class ImpEventAnalyticsComponent implements AfterViewInit {

    @Input() eventName: string;  // The name of the event being analyzed

    // Test mode variables
    useTestData: boolean = false; // Flag to control if test data should be used
    testEventMetrics: any = {
        totalMaleWristbandsScanned: 150,
        totalFemaleWristbandsScanned: 350,
        totalWristbandsScanned: 500,
        freeMaleWristbandsScanned: 20,
        freeFemaleWristbandsScanned: 30,
        paidMaleWristbandsScanned: 130,
        paidFemaleWristbandsScanned: 320,
        ticketDoorRevenue: 8000,
        totalRevenue: 15000,
        tableOrderRevenue: 5000,
        bottleOrderRevenue: 2000,
        eventName: "Money Bag Yo at Dream",
    };

    // Chart.js chart instances for displaying different analytics data
    private barChart: Chart;
    private doughnutChart: Chart;
    private lineChart: Chart;

    @ViewChild("barCanvas") barCanvas: ElementRef;
    @ViewChild("doughnutCanvas") doughnutCanvas: ElementRef;
    @ViewChild("lineCanvas") lineCanvas: ElementRef;

    viewingEventAttendees: boolean = true;
    viewingEventPricing: boolean = true;
    viewingEventRevenue: boolean = true;
    showInfoBox: boolean = true;
    selectedSegment: string = "event-attendees";

    doorTicketPriceLabels: Array<string> = [];
    femaleDoorTicketPriceCounts: Array<number> = [];
    maleDoorTicketPriceCounts: Array<number> = [];
    femaleAveragePrice: number = 0;
    maleAveragePrice: number = 0;
    femaleMinPrice: number = 0;
    maleMinPrice: number = 0;
    femaleMaxPrice: number = 0;
    maleMaxPrice: number = 0;
    totalPriceChanges: number = 0;

    constructor(
        public auth: TdctAuthService,
        public user: TdctUserService,
        public event: ImpEventService,
        public ticket: ImpTicketService,
        public table: ImpTableService,
        public bottle: ImpBottleService,
        public admin: TdctAdminService,
        public modalController: ModalController,
        private cdr: ChangeDetectorRef
    ) {
        console.log('ImpEventAnalyticsComponent constructor called');
    }

    ngOnInit() {
        console.log('ngOnInit called - analytics view is loading');
        if (this.useTestData) {
            this.applyTestData();
        } else {
            this.event.$ = this.event.currentEvent;
        }

        this.initializeEventAttendeesChart();
        this.initializeEventPricesChart();
        this.initializeEventRevenueChart();
    }

    async fetchEventData() {
        try {
            const eventData = await this.event.fetchCurrentEvent(); // Assuming you have a method to fetch current event
            this.event.$ = eventData;
            console.log('Event data refreshed:', this.event.$);
        } catch (error) {
            console.error('Error fetching event data:', error);
        }
    }

    updateCharts() {
        // Destroy existing charts to avoid issues with re-rendering
        if (this.barChart) this.barChart.destroy();
        if (this.doughnutChart) this.doughnutChart.destroy();
        if (this.lineChart) this.lineChart.destroy();

        // Reinitialize charts after data refresh
        this.initializeEventAttendeesChart();
        this.initializeEventPricesChart();
        this.initializeEventRevenueChart();
    }


    applyTestData() {
        console.log('[imp-event-analytics.component.ts] Applying test data');
        this.event.$ = { ...this.event.$, ...this.testEventMetrics, name: "Money Bag Yo at Dream" };
        this.eventName = "Money Bag Yo at Dream";
    }

    ngAfterViewInit() {
        this.selectedSegment = "event-attendees";
        this.initializeCharts();
        this.cdr.detectChanges();
    }

    async initializeCharts() {
        if (this.selectedSegment === "event-attendees" && this.doughnutCanvas?.nativeElement) {
            this.initializeEventAttendeesChart();
        } else if (this.selectedSegment === "event-prices" && this.lineCanvas?.nativeElement) {
            this.initializeEventPricesChart();
        } else if (this.selectedSegment === "event-revenue" && this.barCanvas?.nativeElement) {
            this.initializeEventRevenueChart();
        }
    }

    onSegmentChange(event) {
        this.selectedSegment = event.detail.value;
        setTimeout(() => {
            this.initializeCharts();
        }, 0);
    }

    toggleEventAttendees(): void {
        this.viewingEventAttendees = !this.viewingEventAttendees;
        console.log('toggleEventAttendees called - viewingEventAttendees:', this.viewingEventAttendees);
    }

    toggleEventPrices(): void {
        this.viewingEventPricing = !this.viewingEventPricing;
        console.log('toggleEventPrices called - viewingEventPricing:', this.viewingEventPricing);
    }

    toggleEventRevenue(): void {
        this.viewingEventRevenue = !this.viewingEventRevenue;
        console.log('toggleEventRevenue called - viewingEventRevenue:', this.viewingEventRevenue);
    }

    hideEventAnalytics(): void {
        this.auth.viewingEventAnalytics = false;
        console.log('hideEventAnalytics called - analytics view is hidden');
    }

    dismiss() {
        console.log('dismiss called - closing the modal');
        this.modalController.dismiss();
    }

    async initializeEventAttendeesChart() {
        try {
            if (!this.doughnutCanvas?.nativeElement) return;
            this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
                type: "doughnut",
                data: {
                    labels: ["Female Free", "Female Paid", "Male Free", "Male Paid"],
                    datasets: [
                        {
                            data: [
                                this.event.$.freeFemaleWristbandsScanned || 0,
                                this.event.$.paidFemaleWristbandsScanned || 0,
                                this.event.$.freeMaleWristbandsScanned || 0,
                                this.event.$.paidMaleWristbandsScanned || 0,
                            ],
                            backgroundColor: [
                                "rgba(153, 102, 255, 0.2)",
                                "rgba(255, 99, 132, 0.2)",
                                "rgba(75, 192, 192, 0.2)",
                                "rgba(54, 162, 235, 0.2)",
                            ],
                            hoverBackgroundColor: [
                                "#9966FF",
                                "#FF6384",
                                "#4BC0C0",
                                "#36A2EB",
                            ],
                        },
                    ],
                },
            });
        } catch (error) {
            console.error("Error initializing Event Attendees chart:", error);
        }
    }

    async initializeEventPricesChart() {
        try {
            if (!this.lineCanvas?.nativeElement) return;

            // Log the current event to ensure it's properly loaded
            if (!this.event.$ || !this.event.$.id) {
                console.log('Event is not properly set before filtering tickets:', this.event.$);
                return;
            }

            console.log('Current event:', this.event.$);

            // Reset and load event tickets
            this.doorTicketPriceLabels = [];
            await this.loadEventTickets();
            this.calculateEventTicketStats();

            // Initialize chart
            this.lineChart = new Chart(this.lineCanvas.nativeElement, {
                type: "line",
                data: {
                    labels: this.doorTicketPriceLabels,
                    datasets: [
                        {
                            label: "Female Wristbands Scanned",
                            data: this.femaleDoorTicketPriceCounts,
                            borderColor: "rgba(255, 99, 132, 1)"
                        },
                        {
                            label: "Male Wristbands Scanned",
                            data: this.maleDoorTicketPriceCounts,
                            borderColor: "rgba(54, 162, 235, 1)"
                        }
                    ]
                }
            });
        } catch (error) {
            console.error("Error initializing Event Prices chart:", error);
        }
    }



    isCurrentEvent(): boolean {
        const loadedEventName = this.event.$?.name;
        if (!this.eventName || !loadedEventName) {
            console.log('Event name is missing, cannot verify the current event.');
            return false;
        }

        const isSameEvent = this.eventName === loadedEventName;
        console.log(`Is current event: ${isSameEvent}`);
        return isSameEvent;
    }

    async loadEventTickets() {
        console.log('loadEventTickets called');

        // Ensure the event is properly set before proceeding
        if (!this.event.$ || !this.event.$.id) {
            console.error('Event is not properly set before filtering tickets:', this.event.$);
            return;
        }

        const eventId = this.event.$.id;  // Get the current event ID
        console.log('Loading tickets for event:', eventId);

        try {
            // Clear any previous ticket data
            this.ticket.s$ = [];


            // Call the ticket filtering method in your service with the current event ID
            this.ticket.s$ = await this.event.loadEventTickets();  // This will use the eventId to filter the tickets

            console.log('Tickets loaded successfully:', this.ticket.s$);

            if (this.ticket.s$.length === 0) {
                console.log('No tickets found for event:', eventId);
                return;
            }

            // Process the tickets if they are found
            this.doorTicketPriceLabels = this.processDoorTicketPriceLabels();
            this.femaleDoorTicketPriceCounts = this.processFemaleDoorTicketPriceCounts();
            this.maleDoorTicketPriceCounts = this.processMaleDoorTicketPriceCounts();

        } catch (error) {
            console.error('Error loading event tickets:', error);
        }
    }




    processDoorTicketPriceLabels(): Array<string> {
        console.log('processDoorTicketPriceLabels called');
        const shakenTicketPrices: Array<number> = this.ticket.s$.map((ticket) => ticket.price);
        const uniqueTicketPrices = shakenTicketPrices.filter((v, i, a) => a.indexOf(v) === i);
        const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        const uniqueTicketPricesOrdered = uniqueTicketPrices.sort((a, b) => a - b);
        const uniqueTicketPricesInDollars = uniqueTicketPricesOrdered.map((doorTicketPrice) => currency.format(doorTicketPrice));
        const shortUniqueTicketPricesInDollars = uniqueTicketPricesInDollars.map((ticketPrice) => ticketPrice.substring(0, ticketPrice.length - 3));
        return shortUniqueTicketPricesInDollars;
    }

    processFemaleDoorTicketPriceCounts(): Array<number> {
        console.log('processFemaleDoorTicketPriceCounts called');
        const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        let femaleTicketPriceCounts: Array<number> = [];
        this.doorTicketPriceLabels.forEach((doorTicketPriceLabel) => {
            let count = 0;
            this.ticket.s$.forEach((ticket) => {
                if (ticket.tags && ticket.tags.includes("female")) {
                    const ticketPriceLabel: string = currency.format(ticket.price);
                    const shortTicketPriceLabel: string = ticketPriceLabel.substring(0, ticketPriceLabel.length - 3);
                    if (shortTicketPriceLabel === doorTicketPriceLabel) {
                        ++count;
                    }
                }
            });
            femaleTicketPriceCounts.push(count);
        });
        return femaleTicketPriceCounts;
    }

    processMaleDoorTicketPriceCounts(): Array<number> {
        console.log('processMaleDoorTicketPriceCounts called');
        const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        let maleTicketPriceCounts: Array<number> = [];
        this.doorTicketPriceLabels.forEach((doorTicketPriceLabel) => {
            let count = 0;
            this.ticket.s$.forEach((ticket) => {
                if (ticket.tags && ticket.tags.includes("male")) {
                    const ticketPriceLabel: string = currency.format(ticket.price);
                    const shortTicketPriceLabel: string = ticketPriceLabel.substring(0, ticketPriceLabel.length - 3);
                    if (shortTicketPriceLabel === doorTicketPriceLabel) {
                        ++count;
                    }
                }
            });
            maleTicketPriceCounts.push(count);
        });
        return maleTicketPriceCounts;
    }

    calculateEventTicketStats() {
        console.log('calculateEventTicketStats called');

        // Ensure we are calculating stats for the current event
        if (!this.isCurrentEvent()) {
            console.log('Event mismatch detected during stats calculation.');
            return;
        }

        this.calculateMinAndMaxFemalePrices();
        this.calculateMinAndMaxMalePrices();
        this.femaleAveragePrice = this.calculateFemaleAveragePrice();
        this.maleAveragePrice = this.calculateMaleAveragePrice();
        const uniquePrices = new Set<number>();
        this.ticket.s$.forEach((ticket) => uniquePrices.add(ticket.price));
        this.totalPriceChanges = uniquePrices.size > 0 ? uniquePrices.size : 0;

        console.log('Event ticket statistics calculated here:', {
            femaleAveragePrice: this.femaleAveragePrice,
            maleAveragePrice: this.maleAveragePrice,
            totalPriceChanges: this.totalPriceChanges,
        });
    }

    calculateMinAndMaxFemalePrices() {
        console.log('calculateMinAndMaxFemalePrices called');
        let femaleDoorTicketsPaid: number[] = [];
        this.ticket.s$.forEach((ticket) => {
            if (ticket.tags && ticket.tags.includes("female")) {
                femaleDoorTicketsPaid.push(ticket.price);
            }
        });
        const femaleTicketPricesOrdered: number[] = femaleDoorTicketsPaid.sort((a, b) => a - b);
        this.femaleMinPrice = femaleTicketPricesOrdered.length > 0 ? femaleTicketPricesOrdered[0] : 0;
        this.femaleMaxPrice = femaleTicketPricesOrdered.length > 0 ? femaleTicketPricesOrdered[femaleTicketPricesOrdered.length - 1] : 0;
    }

    calculateMinAndMaxMalePrices() {
        console.log('calculateMinAndMaxMalePrices called');
        let maleDoorTicketsPaid: number[] = [];
        this.ticket.s$.forEach((ticket) => {
            if (ticket.tags && ticket.tags.includes("male")) {
                maleDoorTicketsPaid.push(ticket.price);
            }
        });
        const maleTicketPricesOrdered: number[] = maleDoorTicketsPaid.sort((a, b) => a - b);
        this.maleMinPrice = maleTicketPricesOrdered.length > 0 ? maleTicketPricesOrdered[0] : 0;
        this.maleMaxPrice = maleTicketPricesOrdered.length > 0 ? maleTicketPricesOrdered[maleTicketPricesOrdered.length - 1] : 0;
    }

    calculateFemaleAveragePrice(): number {
        console.log('calculateFemaleAveragePrice called');
        let femaleDoorTicketsPaid: number[] = [];
        this.ticket.s$.forEach((ticket) => {
            if (ticket.tags && ticket.tags.includes("female")) {
                femaleDoorTicketsPaid.push(ticket.price);
            }
        });
        const femaleDoorTicketTotalPaid = femaleDoorTicketsPaid.reduce((previous, current) => current += previous, 0);
        return femaleDoorTicketsPaid.length > 0 ? (femaleDoorTicketTotalPaid / femaleDoorTicketsPaid.length) : 0;
    }

    calculateMaleAveragePrice(): number {
        console.log('calculateMaleAveragePrice called');
        let maleDoorTicketsPaid: number[] = [];
        this.ticket.s$.forEach((ticket) => {
            if (ticket.tags && ticket.tags.includes("male")) {
                maleDoorTicketsPaid.push(ticket.price);
            }
        });
        const maleDoorTicketTotalPaid = maleDoorTicketsPaid.reduce((previous, current) => current += previous, 0);
        return maleDoorTicketsPaid.length > 0 ? (maleDoorTicketTotalPaid / maleDoorTicketsPaid.length) : 0;
    }

    async initializeEventRevenueChart() {
        try {
            if (!this.barCanvas?.nativeElement) return;
            this.barChart = new Chart(this.barCanvas?.nativeElement, {
                type: "bar",
                data: {
                    labels: ["Tickets", "Tables", "Bottles"],
                    datasets: [
                        {
                            data: [
                                this.event.$.ticketDoorRevenue,
                                this.event.$.tableOrderRevenue,
                                this.event.$.bottleOrderRevenue,
                            ],
                            backgroundColor: [
                                "rgba(255, 159, 64, 0.2)",
                                "rgba(255, 206, 86, 0.2)",
                                "rgba(153, 102, 255, 0.2)",
                            ],
                            borderColor: [
                                "rgba(255, 159, 64, 1)",
                                "rgba(255, 206, 86, 1)",
                                "rgba(153, 102, 255, 1)",
                            ],
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (value) {
                                        return parseInt(value) >= 1000
                                            ? "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            : "$" + value;
                                    },
                                },
                            },
                        ],
                    },
                    legend: { display: false },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem) => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
                            title: () => null,
                        },
                    },
                },
            });
        } catch (error) {
            console.error("Error initializing Event Revenue chart:", error);
        }
    }

    async refresh() {
          console.log('Refreshing event data...');
          if (this.useTestData) {
            this.applyTestData();
          } else {
            const event = await this.event.fetchCurrentEvent();  // Fetch live data
            if (event) {
              this.event.$ = event;
            }
          }

          this.updateCharts();  // Reinitialize the charts with new data
        }

}
