import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { ImpBottleService } from '../../imp-bottle/imp-bottle.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';


@Component({
  selector: 'app-imp-table-bottle-list',
  templateUrl: './imp-table-bottle-list.component.html',
  styleUrls: ['./imp-table-bottle-list.component.scss'],
})
export class ImpTableBottleListComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public bottle: ImpBottleService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() { }

}
