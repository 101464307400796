import { Injectable } from '@angular/core';
import { Upload } from './tdct-upload.model';
import { DefaultUpload, MockUploads } from './tdct-upload.mocks';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { finalize, first } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TdctUploadService {

  $: Upload = DefaultUpload;
  s$: Upload[] = MockUploads;
  all$: Upload[] = [];
  doc: AngularFirestoreDocument<Upload>;
  col: AngularFirestoreCollection<Upload>;
  task: AngularFireUploadTask;
  snapshot: Observable<any>;
  search: string = "";
  tag: string = "";
  count: number = 0;
  total: number = 0;
  noneFound: boolean = false;
  editing: boolean = false;
  creating: boolean = false;
  cropping: boolean = false;
  loading: boolean = false;
  selected: boolean = false;
  viewing: boolean = false;
  object: any;
  storagePath: string;
  downloadURL: any;
  percentage: number = 0;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
    public storage: AngularFireStorage,
  ) { }


  async load(): Promise<Upload[]> {
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    this.s$ = this.s$.map(upload => this.formatLastUpdateTimestamp(upload));
    this.s$ = this.s$.map(upload => this.formatUploadCreationDisplayTimestamp(upload));
    if (this.s$.length > 0) {
      this.set(this.s$[0]);
      this.slowCount();
    } else { this.count = 0 }
    this.loading = false;
    return this.s$;
  }

  async filter() {
      this.col = await this.afs.collection<Upload>('uploads', ref => ref.orderBy('unixLastUpdateTimestamp', 'desc'));
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length == 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      let interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    let loadingUploads: Upload[] = [];
    if (this.all$.length == 1) {
      loadingUploads = this.all$.splice(0, 1);
    } else if (this.all$.length == 2) {
      loadingUploads = this.all$.splice(0, 2);
    } else { loadingUploads = this.all$.splice(0, 3); }
    this.s$.push(...loadingUploads);
  }

  set(upload: Upload) {
    this.$ = upload;
    this.selected = true;
    this.viewing = true;
  }

  formatLastUpdateTimestamp(upload: Upload) {
    upload.displayLastUpdateTimestamp = moment.unix(upload.unixLastUpdateTimestamp).fromNow();
    return upload;
  }

  formatUploadCreationDisplayTimestamp(upload: Upload): Upload {
    upload.displayTimestamp = moment.unix(upload.unixTimestamp).format('dddd[,] MMMM D[,] YYYY [at] h:mm A');
    return upload;
  }

  sanitize(): void {
    this.$ = DefaultUpload;
    this.s$ = MockUploads;
    this.all$ = [];
    this.search = "";
    this.noneFound = false;
    this.loading = false;
    this.creating = false;
    this.editing = false;
  }

  async delete() {
    this.loading = true;
    await this.get(this.$.id);
    await this.doc.delete();
    const deletionPromises = [];
    deletionPromises.push(await this.load());
    await Promise.all(deletionPromises);
    this.search = "";
  }

  async toggleView() {
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      await this.get(this.$.id);
      this.set(this.$);
      this.loading = false;
    } else {
      this.editing = false;
      this.loading = false;
    }
    }

    getUploadLink(uploadType: string): string | null {
        if (this.$.link) {
            return this.$.link;  // Return the link of the uploaded image
        }
        console.warn(`No link available for ${uploadType}. Ensure the upload completed successfully.`);
        return null;
    }


  async get(id: string): Promise<Upload> {
    let uploadPath = "uploads/" + id;
    this.doc = this.afs.doc<Upload>(uploadPath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

    async imageToStorage(): Promise<string> {
        this.loading = true;
        const currentUser = await this.auth.afa.currentUser;

        return new Promise<string>((resolve, reject) => {
            try {
                // Create the storage path for the image upload
                this.storagePath = `uploads/images/${currentUser.uid}/image_${moment().unix()}.png`;

                // Start the upload task
                this.task = this.storage.upload(this.storagePath, this.object);
                this.snapshot = this.task.snapshotChanges();

                // Observe the snapshot changes to get upload progress
                this.snapshot.subscribe((uploadSnapshot) => {
                    this.percentage = (uploadSnapshot.bytesTransferred / uploadSnapshot.totalBytes) * 100;
                });

                // Finalize and get the download URL when the upload is complete
                this.snapshot.pipe(finalize(() => {
                    this.downloadURL = this.storage.ref(this.storagePath).getDownloadURL();
                    this.downloadURL.subscribe(url => {
                        this.$.link = url;  // Save the download link in the upload model
                        resolve(url);  // Resolve the promise with the download URL
                    });
                })).subscribe();

            } catch (error) {
                this.auth.presentErrorAlert(error);
                this.loading = false;
                this.creating = false;
                reject(error);
            }
        });
    }

  async create() {
    let currentUser = await this.auth.afa.currentUser;
    this.$.id = this.afs.createId();
    this.$.path = "uploads/images/" + currentUser.uid + "/" + moment().unix() + "_" + currentUser.displayName.toLowerCase() + "_profile_photo" + ".png";
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = moment().format('YYYY-MM-DD');
    this.$.unixLastUpdateTimestamp = moment().unix();
    this.$.unixTimestamp = moment().unix();
    await this.afs.doc('uploads/' + this.$.id).set(this.$);
  }

  toggleCreate(): void {
    this.creating = !this.creating;
  }

  reset(): void {
    this.$ = DefaultUpload;
    this.object = null;
  }
}
