import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { ImpVenueService } from '../imp-venue.service';


@Component({
  selector: 'app-imp-venue-controller',
  templateUrl: './imp-venue-controller.component.html',
  styleUrls: ['./imp-venue-controller.component.scss'],
})
export class ImpVenueControllerComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public theme: ImpThemeService,
    public venue: ImpVenueService
  ) { }

  ngOnInit() { }

  toggleVenueView() {
    this.venue.viewing = !this.venue.viewing;
  }

  toggleVenueCreation() {
    this.auth.viewingVenueCreatorForm = !this.auth.viewingVenueCreatorForm;
  }

}