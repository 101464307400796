import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-ping-controller',
  templateUrl: './tdct-ping-controller.component.html',
  styleUrls: ['./tdct-ping-controller.component.scss'],
})
export class TdctPingControllerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
