import { Injectable } from '@angular/core';
import { Message } from './tdct-message.model';
import { DefaultMessage, MockMessages } from './tdct-message.mocks';

@Injectable({
  providedIn: 'root'
})
export class TdctMessageService {

  $: Message = DefaultMessage;
  s$: Message[] = MockMessages;
  count: number = 0;

  constructor() { }
}
