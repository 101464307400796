import { Bottle } from './imp-bottle.model';
export const PaidBottle: Bottle = {
    id: '1',
    name: 'Paid Bottle',
    description: 'Paid Bottle Description',
    image: 'assets/img/icon.png',
    link: 'https://imp-events.com/event/1',
    route: 'event/1',
    country: '001',
    state: 'MI',
    city: 'Detroit',
    street: '1 Jefferson Ave',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    eventId: '2',
    eventName: 'Detroit Event',
    eventDescription: 'Motown Chronicles',
    eventImage: 'assets/img/eggnog.jpg',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    price: 10,
    zipcode: '60007',
    amountPurchased: 1,
    tags: []
};
export const CompBottle: Bottle = {
    id: '2',
    name: 'Complimentary Bottle',
    description: 'Complimentary Bottle Description',
    image: 'assets/img/icon.png',
    link: 'https://imp-events.com/event/2',
    route: 'event/2',
    country: '001',
    state: 'MI',
    city: 'Detroit',
    street: '1 Jefferson Ave',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    eventId: '2',
    eventName: 'Detroit Event',
    eventDescription: 'Motown Chronicles',
    eventImage: 'assets/img/eggnog.jpg',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    price: 10,
    zipcode: '60007',
    amountPurchased: 1,
    tags: []
};
export const MockBottles: Bottle[] = [PaidBottle, CompBottle];
export const DefaultBottle: Bottle = {
    id: '',
    name: '',
    description: '',
    country: '',
    state: '',
    city: '',
    street: '',
    image: '',
    link: '',
    route: '',
    userId: '',
    userEmail: '',
    userName: '',
    userPhoto: '',
    promoterId: '1',
    promoterEmail: 'chicago-promoter@tdct.io',
    promoterName: 'Chicago Promoter',
    promoterBio: 'I promote the best events in the Chicago area',
    promoterPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixTimestamp: 0,
    unixLastUpdateTimestamp: 0,
    price: 0,
    zipcode: '',
    amountPurchased: 0,
    tags: [],
    bottlesChosen: 0
};

