import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event.service';
import { ImpBottleService } from '../../imp-bottle/imp-bottle.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';


@Component({
  selector: 'app-imp-event-bottle-option-list',
  templateUrl: './imp-event-bottle-option-list.component.html',
  styleUrls: ['./imp-event-bottle-option-list.component.scss'],
})
export class ImpEventBottleOptionListComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public bottle: ImpBottleService,
    public option: ImpOptionService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() { }

}
