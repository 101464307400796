import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-ticket-detail-modal',
  templateUrl: './ticket-detail-modal.component.html',
  styleUrls: ['./ticket-detail-modal.component.scss'],
})
export class TicketDetailModalComponent {
  @Input() ticket: any;

  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss(); // This should be the instance's dismiss method
  }
}
