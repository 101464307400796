import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-ping',
  templateUrl: './tdct-ping.component.html',
  styleUrls: ['./tdct-ping.component.scss'],
})
export class TdctPingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
