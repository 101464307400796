import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TdctAuthService } from '../../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user.service';
import { TdctAdminService } from '../../../tdct-admin/tdct-admin.service';
import { TdctUserPromoterService } from '../tdct-user-promoter.service';
import { ImpCityService } from 'src/app/home/imp-city/imp-city.service';

@Component({
  selector: 'app-tdct-user-promoter-controller',
  templateUrl: './tdct-user-promoter-controller.component.html',
  styleUrls: ['./tdct-user-promoter-controller.component.scss'],
})
export class TdctUserPromoterControllerComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public promoter: TdctUserPromoterService,
    private route: ActivatedRoute,
    private city: ImpCityService, 
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const cityName = params['cityName'];
      this.loadCityIdAndPromoters(cityName);
    });
  }

  async loadCityIdAndPromoters(cityName: string): Promise<void> {
    const cityId = await this.city.getCityIdByName(cityName);
    if (cityId) {
      await this.promoter.loadPromotersForCity(cityId);
      this.cd.detectChanges();
    }
  }

  togglePromoterView(): void {
    if (this.auth.viewingEventAnalytics) { this.auth.viewingEventAnalytics = false; }
    else if (this.auth.uploadingUserPromoterImage) { this.auth.uploadingUserPromoterImage = false; }
    else if (this.promoter.editing) { this.promoter.editing = false; }
    else { this.promoter.viewing = !this.promoter.viewing; }
  }

  togglePromoterCreatorForm(): void {
    this.auth.viewingUserPromoterCreatorForm = !this.auth.viewingUserPromoterCreatorForm;
  }

  onCitySelect(cityId: string): void {
    this.promoter.s$ = []; 
    this.promoter.loadPromotersForCity(cityId).then(() => {
      this.cd.detectChanges(); 
    });
  }
}
