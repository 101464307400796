import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent} from './imp-modal.component';
import { ImpPrivacyComponent } from '../imp-privacy/imp-privacy.component';
import { ImpTermsComponent } from '../imp-terms/imp-terms.component';
import { ImpTermsStaticComponent } from '../imp-terms-static/imp-terms-static.component';
import { ImpContactComponent } from '../imp-contact/imp-contact.component';
import { ImpServiceComponent } from '../imp-service/imp-service.component';
import { IonicModule } from '@ionic/angular';
import { ImpPrivacyStaticComponent } from '../imp-privacy-static/imp-privacy-static.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    ModalComponent,
    ImpPrivacyComponent,
    ImpTermsComponent,
    ImpContactComponent,
    ImpServiceComponent,
    ImpTermsStaticComponent,
    ImpPrivacyStaticComponent,
  ],
  exports: [
    ModalComponent,
  ]
})
export class ModalModule {}
