import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-device',
  templateUrl: './tdct-device.component.html',
  styleUrls: ['./tdct-device.component.scss'],
})
export class TdctDeviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
