import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event.service';
import { ImpTicketService } from '../../imp-ticket/imp-ticket.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';



@Component({
  selector: 'app-imp-event-ticket-option-list',
  templateUrl: './imp-event-ticket-option-list.component.html',
  styleUrls: ['./imp-event-ticket-option-list.component.scss'],
})
export class ImpEventTicketOptionListComponent implements OnInit{
  @Input()loggedOutTicketOptions: any[];
  @Input() isLoggedOut:boolean;
  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public ticket: ImpTicketService,
    public option: ImpOptionService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() {
    if (this.auth.viewingEventStripeElementsForm) { this.auth.viewingEventStripeElementsForm = false; }
    console.log("loggedoutticketoptions ", this.loggedOutTicketOptions);
   }
  

}
