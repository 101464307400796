import { Injectable } from '@angular/core';
import { Promoter } from './tdct-user-promoter.model';
import { DefaultPromoter, MockPromoters } from './tdct-user-promoter.mocks';
import { AngularFirestore, AngularFirestoreCollectionGroup, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import * as moment from 'moment';
import { first, take } from 'rxjs/operators';
import { ImpCityService } from '../../imp-city/imp-city.service';

@Injectable({
  providedIn: 'root'
})
export class TdctUserPromoterService {

  $: Promoter = DefaultPromoter;
  s$: Promoter[] = MockPromoters;
  all$: Promoter[] = [];
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false; // Ensure this is properly handled
  editing: boolean = false;
  promoterGroup: AngularFirestoreCollectionGroup<Promoter>;
  doc: AngularFirestoreDocument<Promoter>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public city: ImpCityService,
    public admin: TdctAdminService,
    public afs: AngularFirestore,
  ) { }

  async get(promoterId: string): Promise<Promoter> {
    const userPath = `users/${this.user.$.id}/promoters/${promoterId}`;
    this.doc = this.afs.doc<Promoter>(userPath);
    return await this.doc.valueChanges().pipe(take(1)).toPromise();
  }

  set(user: Promoter): void {
    this.$ = user;
    this.viewing = true;
    this.$ = this.momentLastUpdatedFromNow(this.$);
    this.$ = this.momentCreatedExactly(this.$);
  }

  async load(): Promise<Promoter[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.promoterGroup.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$ = this.s$.map(promoter => this.auth.momentCreatedExactly(promoter));
      this.s$ = this.s$.map(promoter => this.auth.momentLastUpdatedFromNow(promoter));
      this.set(this.s$[0]);
      this.slowCount();
      this.noneFound = false; // Set noneFound to false if promoters are found
    } else {
      this.noneFound = true; // Set noneFound flag if no promoters found
      this.viewing = false; // Ensure viewing flag is false when no promoters are found
    }
    this.loading = false;
    return this.s$;
  }

  async filter() {
    if (this.auth.viewingCityPromoters) {
      this.promoterGroup = await this.afs.collectionGroup<Promoter>('promoters', ref => ref
        .where('cities', 'array-contains', this.city.$.id)
        .where('approved', '==', true)
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    } else if (this.auth.viewingManagedUserPromoterProfiles || this.auth.viewingUserPromoter) {
      this.promoterGroup = await this.afs.collectionGroup<Promoter>('promoters', ref => ref
        .where('userId', '==', this.user.$.id)
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    } else {
      this.promoterGroup = await this.afs.collectionGroup<Promoter>('promoters', ref => ref
        .where('userId', '==', this.user.$.id)
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    }
  }

  async loadPromotersForCity(cityId: string): Promise<void> {
    this.loading = true;
    this.s$ = [];
    this.all$ = [];
    this.total = 0;
    this.count = 0;
    this.noneFound = false; // Reset noneFound flag when loading promoters for a new city
    this.viewing = false; // Ensure viewing flag is false when loading new promoters

    // Update filter logic to load promoters for the specified city
    this.promoterGroup = this.afs.collectionGroup<Promoter>('promoters', ref => ref
      .where('cities', 'array-contains', cityId)
      .where('approved', '==', true)
      .orderBy('unixLastUpdateTimestamp', 'desc'));

    this.s$ = await this.promoterGroup.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$ = this.s$.map(promoter => this.auth.momentCreatedExactly(promoter));
      this.s$ = this.s$.map(promoter => this.auth.momentLastUpdatedFromNow(promoter));
      this.set(this.s$[0]);
      this.slowCount();
      this.noneFound = false; // Set noneFound to false if promoters are found
    } else {
      this.noneFound = true; // Set noneFound flag if no promoters found
    }
    this.loading = false;
  }

  momentCreatedExactly(digit: any): any {
    digit.displayTimestamp = moment.unix(digit.unixTimestamp).format('dddd[,] MMMM Do[,] YYYY [at] h:mm a');
    return digit;
  }

  momentLastUpdatedFromNow(digit: any): any {
    digit.displayLastUpdateTimestamp = moment.unix(digit.unixLastUpdateTimestamp).fromNow();
    return digit;
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    let loadingPromoters: Promoter[] = [];
    if (this.all$.length === 1) {
      loadingPromoters = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadingPromoters = this.all$.splice(0, 2);
    } else {
      loadingPromoters = this.all$.splice(0, 3);
    }
    this.s$.push(...loadingPromoters);
  }

  sanitize(): void {
    this.s$ = [];
    this.auth.viewingManagedUserPromoterProfiles = false;
    this.auth.viewingUserPromoterEventCreatorForm = false;
  }

  clear(): void {
    this.editing = false;
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }
}
