import { Component, OnInit, Renderer2, ElementRef, QueryList, ViewChildren, AfterViewInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonTabs, NavController } from '@ionic/angular';
import { ImpCityService } from '../home/imp-city/imp-city.service';
import { ImpThemeService } from '../home/imp-theme/imp-theme.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})

export class TabsPage implements OnInit, AfterViewInit {

  @ViewChildren('tabButton', { read: ElementRef }) tabButtons: QueryList<ElementRef>;
  @ViewChild(IonTabs, { static: true }) ionTabs: IonTabs;

  currentTab: string;
  cityName: string | null = null;

  constructor(
    private navCtrl: NavController,
    private router: Router, 
    private renderer: Renderer2,
    public theme: ImpThemeService,

    private city: ImpCityService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentTab = this.getTabFromUrl(event.urlAfterRedirects);
        this.updateTabStyles();
      }
    });
  }

  ngOnInit() {
    this.cityName = this.city.getSelectedCity()?.name || this.cityName;
    this.updateTabStyles();
  }

  ngAfterViewInit() {
    this.updateTabStyles();

    this.ionTabs.ionTabsDidChange.subscribe(() => {
      this.currentTab = this.ionTabs.getSelected();
      this.cityName = this.city.getSelectedCity()?.name || this.cityName;
      this.updateTabStyles();
    });
  }
  
  goToRoot(tab: string) {
    let navigationExtras = {};
    this.cityName = this.city.getSelectedCity()?.name || this.cityName;

    if (tab === 'events' && this.cityName) {
      navigationExtras = { queryParams: { cityName: this.cityName.replace(/ /g, '-')  } };
    }
    this.navCtrl.navigateRoot([`/${tab}`], navigationExtras);
    this.currentTab = tab;
    this.updateTabStyles();
  }

  isActive(tab: string): boolean {
    return this.currentTab === tab;
  }

updateTabStyles() {
  if (this.tabButtons) {
    this.tabButtons.forEach((tabButton: ElementRef) => {
      const tab = tabButton.nativeElement.getAttribute('tab'); // Get the tab attribute
      const icon = tabButton.nativeElement.querySelector('ion-icon');

      // Check if dark mode is active
      if (this.theme.dark) {
        // Dark mode colors
        if (this.isActive(tab)) {
          this.renderer.setStyle(icon, 'color', '#C82D25'); // Active color in dark mode
        } else {
          this.renderer.setStyle(icon, 'color', '#8A8A8F'); // Inactive color in dark mode (lighter gray)
        }
      } else {
        // Light mode colors
        if (this.isActive(tab)) {
          this.renderer.setStyle(icon, 'color', '#C82D25'); // Active color in light mode
        } else {
          this.renderer.setStyle(icon, 'color', '#4A4A4A'); // Inactive color in light mode (darker gray)
        }
      }
    });
  }
}



  private getTabFromUrl(url: string): string {
    const path = url.split('?')[0]; 
    return path.split('/')[1]; 
  }
}
