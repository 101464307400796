import { Ping } from './tdct-ping.model';
export const UserPing: Ping = {
    id: "1",
    name: "User Ping",
    description: "New User Joined",
    senderId: "2",
    senderName: "Jane",
    senderEmail: "test-jane@tdct.io",
    senderPhoto: "assets/img/default-profile.png",
    receiverId: "3",
    receiverName: "Jim",
    receiverEmail: "test-jim@tdct.io",
    receiverPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-04-26",
    displayLastUpdateTimestamp: "2019-04-26",
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ["user"]
}
export const SubscriptionPing: Ping = {
    id: "2",
    name: "Subscription Ping",
    description: "New User Subscription",
    senderId: "2",
    senderName: "Jane",
    senderEmail: "test-jane@tdct.io",
    senderPhoto: "assets/img/default-profile.png",
    receiverId: "3",
    receiverName: "Jim",
    receiverEmail: "test-jim@tdct.io",
    receiverPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-04-26",
    displayLastUpdateTimestamp: "2019-04-26",
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ["subscription"]
}
export const MockPings: Ping[] = [UserPing, SubscriptionPing];
export const DefaultPing: Ping = {
    id: "",
    name: "",
    description: "",
    senderId: "",
    senderName: "",
    senderEmail: "",
    senderPhoto: "",
    receiverId: "",
    receiverName: "",
    receiverEmail: "",
    receiverPhoto: "",
    displayTimestamp: "",
    displayLastUpdateTimestamp: "",
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    tags: []
}