
import { City } from './imp-city.model';
export const DetroitCity: City = {
    id: "1",
    name: "Detroit",
    description: "Detroit, Michigan",
    link: "https://imp-events.com/city/1",
    route: "city/1",
    alias: "detroit",
    image: "assets/img/detroit.jpg",
    country: "001",
    state: "MI",
    userId: "2",
    userEmail: "test-jane@tdct.io",
    userName: "Jane",
    userPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-03-08",
    displayLastUpdateTimestamp: "a few seconds ago",
    unixTimestamp: 1552070016,
    unixLastUpdateTimestamp: 1552070016,
    promoterCount: 0,
    eventCount: 0,
    tags: [],
    events: ["2"],
    timezone: "US/Central",
}
export const ChicagoCity: City = {
    id: "2",
    name: "Chicago",
    description: "Chicago, Illnois",
    link: "https://imp-events.com/city/1",
    route: "city/1",
    alias: "chicago",
    image: "assets/img/chicago.jpg",
    country: "001",
    state: "MI",
    userId: "2",
    userEmail: "test-jane@tdct.io",
    userName: "Jane",
    userPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-03-08",
    displayLastUpdateTimestamp: "a few seconds ago",
    unixTimestamp: 1552070016,
    unixLastUpdateTimestamp: 1552070016,
    promoterCount: 0,
    eventCount: 0,
    tags: [],
    events: ["1"],
    timezone: "US/Central",
}
export const MiamiCity: City = {
    id: "3",
    name: "Miami",
    description: "Miami, Florida",
    link: "https://imp-events.com/city/1",
    route: "city/1",
    alias: "miami",
    image: "assets/img/miami-city.jpg",
    country: "001",
    state: "MI",
    userId: "2",
    userEmail: "test-jane@tdct.io",
    userName: "Jane",
    userPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-03-08",
    displayLastUpdateTimestamp: "a few seconds ago",
    unixTimestamp: 1552070016,
    unixLastUpdateTimestamp: 1552070016,
    promoterCount: 0,
    eventCount: 0,
    tags: [],
    events: ["3"],
    timezone: "US/Eastern",
}
export const RichmondCity: City = {
    id: "4",
    name: "Richmond",
    description: "Richmond, Virginia",
    link: "https://imp-events.com/city/1",
    route: "city/1",
    alias: "richmond",
    image: "assets/img/richmond-city.jpg",
    country: "001",
    state: "MI",
    userId: "2",
    userEmail: "test-jane@tdct.io",
    userName: "Jane",
    userPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-03-08",
    displayLastUpdateTimestamp: "a few seconds ago",
    unixTimestamp: 1552070016,
    unixLastUpdateTimestamp: 1552070016,
    promoterCount: 0,
    eventCount: 0,
    tags: [],
    events: ["4"],
    timezone: "US/Mountain",
}
export const MockCities: City[] = [DetroitCity, ChicagoCity, MiamiCity, RichmondCity];
export const DefaultCity: City = {
    id: "",
    name: "",
    description: "",
    image: "assets/img/chicago.jpg",
    link: "",
    route: "",
    alias: "",
    country: "",
    state: "",
    userId: "",
    userEmail: "",
    userName: "",
    userPhoto: "",
    displayTimestamp: "",
    displayLastUpdateTimestamp: "",
    unixTimestamp: 0,
    unixLastUpdateTimestamp: 0,
    promoterCount: 0,
    eventCount: 0,
    tags: ["featured"],
    events: [],
    timezone: "US/Central",
}