export interface Venue {
    id: string;
    name: string;
    description: string;
    image: string;
    link: string;
    route: string;
    userId: string;
    userEmail: string;
    userName: string;
    userPhoto: string;
    displayTimestamp: string;
    displayLastUpdateTimestamp: string;
    unixTimestamp: number;
    unixLastUpdateTimestamp: number;
    address: string;
    state: string;
    country: string;
    city: string;
    phoneNumber: string;
    zipcode: string;
    tags: Array<string>;
    events: Array<string>;
    cities: Array<string>;
    displayApprovalTimestamp?: string;
    unixApprovalTimestamp?: number;
}
export const EmptyVenue: Venue = {
    id: "",
    name: "",
    description: "",
    country: "",
    address: "",
    state: "",
    city: "",
    image: "",
    link: "",
    route: "",
    userId: "",
    userEmail: "",
    userName: "",
    userPhoto: "",
    displayTimestamp: "",
    displayLastUpdateTimestamp: "",
    phoneNumber: "555-555-5555",
    unixTimestamp: 0,
    unixLastUpdateTimestamp: 0,
    zipcode: '',
    tags: [],
    events: [],
    cities: []
}
