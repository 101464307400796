import { Venue } from './imp-venue.model';
export const OpenVenue: Venue = {
    id: "1",
    name: "Detroit Venue",
    description: "Open Detroit Venue",
    link: "https://imp-events.com/venue/1",
    route: "venue/1",
    image: "assets/img/detroit.jpg",
    country: "001",
    state: "MI",
    city: "Detroit",
    userId: "2",
    address: "123 Happ Lane",
    userEmail: "test-jane@tdct.io",
    userName: "Jane",
    userPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-03-08",
    displayLastUpdateTimestamp: "2019-03-08",
    unixTimestamp: 1552070016,
    unixLastUpdateTimestamp: 1552070016,
    zipcode: '48000',
    phoneNumber: "555-555-5555",
    tags: ["approved"],
    events: ["2"],
    cities: ["2"],
    displayApprovalTimestamp: "2019-03-08",
    unixApprovalTimestamp: 1552070016
}
export const ClosedVenue: Venue = {
    id: "1",
    name: "Detroit Venue",
    description: "Closed Detroit Venue",
    link: "https://imp-events.com/venue/1",
    route: "venue/1",
    image: "assets/img/detroit.jpg",
    country: "001",
    state: "MI",
    city: "Detroit",
    userId: "2",
    address: "123 Happ Lane",
    userEmail: "test-jane@tdct.io",
    userName: "Jane",
    userPhoto: "assets/img/default-profile.png",
    displayTimestamp: "2019-03-08",
    displayLastUpdateTimestamp: "2019-03-08",
    unixTimestamp: 1552070016,
    unixLastUpdateTimestamp: 1552070016,
    zipcode: '48000',
    phoneNumber: "555-555-5555",
    tags: [],
    events: ["2"],
    cities: ["2"],
}
export const MockVenues: Venue[] = [OpenVenue, ClosedVenue];
