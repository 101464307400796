import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImpEmitterService {
  public ServiceTermsReviewed: EventEmitter<boolean> = new EventEmitter<boolean>();
  public PromoterChanged;
  constructor() {
  }

  public acceptTerms(response: boolean) {
    this.ServiceTermsReviewed.emit(response);
    console.log('Service received response:' + response);
  }



}
