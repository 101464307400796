import { Injectable } from '@angular/core';
import { EmptyVenue, Venue } from './imp-venue.model';
import { MockVenues } from './imp-venue.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import * as moment from 'moment';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpCityService } from '../imp-city/imp-city.service';

@Injectable({
  providedIn: 'root'
})
export class ImpVenueService {

  $: Venue = EmptyVenue;
  s$: Venue[] = MockVenues;
  all$: Venue[] = [];
  sHidden$: Venue[] = [];
  search: string = "";
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  doc: AngularFirestoreDocument<Venue>;
  col: AngularFirestoreCollection<Venue>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public city: ImpCityService,
    public promoter: TdctUserPromoterService,
    public afs: AngularFirestore
  ) { }

  async create() {
    try {
      this.loading = true;
      this.$.id = this.afs.createId();
      this.stampTime();
      this.stampUser();
      await this.afs.doc('venues/' + this.$.id).set(this.$);
      const venueCreationPromises = []
      venueCreationPromises.push(this.get(this.$.id));
      await Promise.all(venueCreationPromises);
      this.loading = false;
    } catch (error) {
    }
  }

  stampTime(): void {
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = this.$.displayTimestamp;
    this.$.unixTimestamp = moment().unix();
    this.$.unixLastUpdateTimestamp = this.$.unixTimestamp;
  }

  stampUser(): void {
    this.$.userId = this.user.$.id;
    this.$.userName = this.user.$.name;
    this.$.userEmail = this.user.$.email;
    this.$.userPhoto = this.user.$.photo;
  }

  async get(id: string): Promise<Venue> {
    let venuePath = "venues/" + id;
    this.doc = this.afs.doc<Venue>(venuePath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  set(venue: Venue): void {
    this.$ = venue;
    this.viewing = true;
  }

  async load(): Promise<Venue[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$ = this.s$.map(venue => this.auth.momentCreatedExactly(venue));
      this.s$ = this.s$.map(venue => this.auth.momentLastUpdatedFromNow(venue));
      this.set(this.s$[0]);
      this.slowCount();
    }
    this.loading = false
    return this.s$;
  }

  async filter() {
    this.col = await this.afs.collection<Venue>('venues', ref => ref.orderBy('unixLastUpdateTimestamp', 'desc'));
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length == 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      let interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    let loadinVenues: Venue[] = [];
    if (this.all$.length == 1) {
      loadinVenues = this.all$.splice(0, 1);
    } else if (this.all$.length == 2) {
      loadinVenues = this.all$.splice(0, 2);
    } else { loadinVenues = this.all$.splice(0, 3); }
    this.s$.push(...loadinVenues);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleView(): void {
    this.clear();
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      this.set(this.$);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }
}
