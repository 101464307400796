import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-upload-controller',
  templateUrl: './tdct-upload-controller.component.html',
  styleUrls: ['./tdct-upload-controller.component.scss'],
})
export class TdctUploadControllerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
