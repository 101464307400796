import { Component, OnInit, Input, HostListener,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpTableService } from '../imp-table.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { Option } from '../../imp-option/imp-option.model';
import { DefaultOption } from '../../imp-option/imp-option.mocks';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ImpBottleService } from '../../imp-bottle/imp-bottle.service';
import { DefaultBottle } from '../../imp-bottle/imp-bottle.mocks';
import * as moment from 'moment';
import { PopoverController } from '@ionic/angular';
import { ImpBottleOptionOptionComponent } from '../../imp-bottle/imp-bottle-option-option/imp-bottle-option-option.component';
import { ImpEventService } from '../../imp-event/imp-event.service';


@Component({
  selector: 'app-imp-table-bottle-option',
  templateUrl: './imp-table-bottle-option.component.html',
  styleUrls: ['./imp-table-bottle-option.component.scss'],
})
export class ImpTableBottleOptionComponent implements OnInit {

  @Input() option$: Option = DefaultOption;
  handler: StripeCheckoutHandler;

  confirmation: any;
  loading = false;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public table: ImpTableService,
    public bottle: ImpBottleService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
    public popover: PopoverController
  ) { }

  ngOnInit() {
    this.bottle.$ = DefaultBottle;
    this.handler = StripeCheckout.configure({
      key: 'pk_test_ZSrFnMRNZDWqutTxYuR50xxK',
      image: '/assets/img/icon.png',
      locale: 'auto',
      source: async (source) => {
        this.loading = true;
        await this.createBottle(source);
        this.loading = false;
      }
    });
  }

  async createBottle(source: any) {
    this.bottle.$.id = this.afs.createId();
    this.bottle.$.stripeCardToken = source.id;
    this.bottle.$.name = `${this.option.$.name} bottle to ${this.table.$.name}`;
    this.bottle.$.description = `This is ${this.user.$.name}'s bottle ${this.option.$.name} bottle to ${this.table.$.name}, which is ${this.option.$.description}`;
    this.bottle.$.link = `https://imp-tables.com/bottle/${this.bottle.$.id}`;
    this.bottle.$.route = `bottle/${this.bottle.$.id}`;
    this.bottle.$.price = this.option.$.price;
    this.stampUser();
    this.stampPromoter();
    this.stampTime();
    this.stampEvent();
    this.stampTable();
    return await this.afs.doc(`bottles/${this.bottle.$.id}`).set(this.bottle.$);
  }

  stampTime() {
    this.bottle.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.bottle.$.displayLastUpdateTimestamp = this.bottle.$.displayTimestamp;
    this.bottle.$.unixTimestamp = moment().unix();
    this.bottle.$.unixLastUpdateTimestamp = this.bottle.$.unixTimestamp;
  }

  stampUser() {
    this.bottle.$.userId = this.user.$.id;
    this.bottle.$.userEmail = this.user.$.email;
    this.bottle.$.userName = this.user.$.name;
    this.bottle.$.userPhoto = this.user.$.photo;
  }

  stampPromoter() {
    this.bottle.$.promoterId = this.option.$.promoterId;
    this.bottle.$.promoterEmail = this.option.$.promoterEmail;
    this.bottle.$.promoterName = this.option.$.promoterName;
    this.bottle.$.promoterPhoto = this.option.$.promoterPhoto;
    this.bottle.$.promoterBio = this.option.$.promoterBio;
  }


  stampEvent() {
    this.table.$.eventId = this.event.$.id;
    this.table.$.eventName = this.event.$.name;
    this.table.$.eventImage = this.event.$.image;
    this.table.$.eventDescription = this.event.$.description;
    this.table.$.displayStartTimestamp = this.event.$.displayStartTimestamp;
    this.table.$.displayEndTimestamp = this.event.$.displayEndTimestamp;
    this.table.$.unixStartTimestamp = this.event.$.unixStartTimestamp;
    this.table.$.unixEndTimestamp = this.event.$.unixEndTimestamp;
    this.table.$.street = this.event.$.street;
    this.table.$.city = this.event.$.city;
    this.table.$.state = this.event.$.state;
    this.table.$.zipcode = this.event.$.zipcode;
    this.table.$.country = this.event.$.country;
  }

  stampTable() {
    this.bottle.$.tableId = this.table.$.id;
    this.bottle.$.tableName = this.table.$.name;
    this.bottle.$.tableImage = this.table.$.image;
    this.bottle.$.tableDescription = this.table.$.description;
  }

  async checkout(e) {
    this.option.set(this.option$);
    this.handler.open({
      name: this.option.$.name,
      description: this.option.$.description,
      amount: this.option.$.price * 100,
      email: this.user.$.email,
    });
    e.prtableDefault();
  }

  @HostListener('window:popstate')
  onPopstate() {
    this.handler.close();
  }

  toggleBottleOptionManager(): void {
    this.auth.viewingTableBottleOptionPopover = !this.auth.viewingTableBottleOptionPopover;
    if (this.auth.viewingTableBottleOptionPopover) {
      this.presentPopover();
    }
  }

  async presentPopover() {
    this.auth.optionControl = await this.popover.create({
      component: ImpBottleOptionOptionComponent,
      showBackdrop: false,
      animated: true
    });
    this.auth.optionControl.onDidDismiss().then(() => {
      this.auth.viewingTableBottleOptionPopover = false;
    });
    return await this.auth.optionControl.present();
  }
}
