import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-post',
  templateUrl: './tdct-post.component.html',
  styleUrls: ['./tdct-post.component.scss'],
})
export class TdctPostComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
