import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImpTicketService } from '../imp-ticket.service';
import { Ticket } from '../imp-ticket.model';
import { TicketDetailModalComponent } from '../ticket-detail-modal/ticket-detail-modal.component';
import { CommonModule, DatePipe } from '@angular/common';

@Component({
  selector: 'app-imp-ticket-controller',
  templateUrl: './imp-ticket-controller.component.html',
  styleUrls: ['./imp-ticket-controller.component.scss'],
})
export class ImpTicketControllerComponent implements OnInit {
  selectedTicket: Ticket | null = null;
  itemsToShow = 10; 
  tickets: Ticket[] = [];  
  
  constructor(public ticket: ImpTicketService, private modalController: ModalController) {}

  ngOnInit() {}

  showMore() {
    this.itemsToShow += 10; 
  }

  selectTicket(ticket: Ticket) {
    this.selectedTicket = ticket;
    this.openTicketModal(ticket);
  }

  async openTicketModal(ticket: Ticket) {
    const modal = await this.modalController.create({
      component: TicketDetailModalComponent,
      componentProps: { ticket }
    });
    return await modal.present();
  }
}
