import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';


@Component({
  selector: 'app-tdct-user-controller',
  templateUrl: './tdct-user-controller.component.html',
  styleUrls: ['./tdct-user-controller.component.scss'],
})
export class TdctUserControllerComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() { }

  toggleCreatorForm(): void {
    this.auth.viewingUserCreatorForm = !this.auth.viewingUserCreatorForm;
  }

  toggleUserView(): void {
    if (this.auth.viewingUserPromoterCreatorForm) { this.auth.viewingUserPromoterCreatorForm = false; }
    if (this.user.editing) { this.user.editing = false; }
    if (this.auth.viewingUserPromoterEventCreatorForm) { this.auth.viewingUserPromoterEventCreatorForm = false; }
    else {
      this.user.viewing = !this.user.viewing;
      if (this.user.viewing) {
        this.user.loading = true;
        this.user.set(this.user.$);
        this.user.loading = false;
      } else {
        this.user.loading = false;
      }
    }
  }
}