import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-imp-theme',
  templateUrl: './imp-theme.component.html',
  styleUrls: ['./imp-theme.component.scss'],
})
export class ImpThemeComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
