import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SitemapComponent } from './sitemap/sitemap.component';  // Add this import
import { AnalyticsGuard } from './guards/analytics.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'login', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'signup', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'reset', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'city/:id', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'cities', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin', redirectTo: 'admin/manage', pathMatch: 'full' },
  { path: 'admin/manage', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/engine', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/engine/brand', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/engine/core', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/events', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/cities', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/venues', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/users', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/user/:id/promoter/profiles', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/promoters', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/promoter/profiles', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/promoter/events', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/manage/promoter/requests', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user', redirectTo: 'user/profile', pathMatch: 'full' },
  { path: 'user/profile', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user/:userId/promoter/:promoterId', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user/:userId/promoter/:promoterId/analytics', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user/:userId/promoter/profiles', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user/profile/promoter/events', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user/profile/promoter/event/tickets', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user/profile/promoter/event/tables', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'user/account', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'event/:id', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'event/redirectFromCheckout/:id', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'event/:id/analytics', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'events/featured', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'table/:id', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'ticket/:id', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'scanner', loadChildren: () => import('./scanner/scanner.module').then(m => m.ScannerPageModule) },
  { path: 'scanner/promoter/:promoterId/event/:eventId', loadChildren: () => import('./scanner/scanner.module').then(m => m.ScannerPageModule) },
  { path: 'venue/:venueId', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'terms', loadChildren: () => import('./home/imp-terms-static/imp-terms-static.module').then(m => m.ImpTermsStaticModule) },
  { path: 'privacy', loadChildren: () => import('./home/imp-privacy-static/imp-privacy-static.module').then(m => m.ImpPrivacyStaticModule) },
  { path: 'order/success', loadChildren: () => import("./home/home.module").then(m => m.HomePageModule)},
  { path: 'order/cancel',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'sitemap', component: SitemapComponent },
  { path: 'promoter/settings', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'promoter/analytics',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'promoter/create/event',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'promoter/profile',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'admin/dashboard',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'admin/settings',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'admin/promoter/panel',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: 'admin/analytics',  loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsPageModule),
    canActivate: [AnalyticsGuard]
  },

  // { path: 'orders', component: UserOrdersComponent }, // Route for /orders
  // {
  //   path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersPageModule)
  // },
  // { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
  // { path: 'tab1', loadChildren: './tab1/tab1.module#Tab1PageModule' },
  // { path: 'tab2', loadChildren: './tab2/tab2.module#Tab2PageModule' },
  // { path: 'tab3', loadChildren: './tab3/tab3.module#Tab3PageModule' },



  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
];

@NgModule({
  // imports: [
  //   RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  // ],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
