import { Component, OnInit, Input, HostListener,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { Option } from '../../imp-option/imp-option.model';
import { DefaultOption } from '../../imp-option/imp-option.mocks';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ImpBottleService } from '../../imp-bottle/imp-bottle.service';
import { PopoverController } from '@ionic/angular';
import { ImpBottleOptionOptionComponent } from '../../imp-bottle/imp-bottle-option-option/imp-bottle-option-option.component';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { ImpTableService } from '../../imp-table/imp-table.service';



@Component({
  selector: 'app-imp-event-bottle-option',
  templateUrl: './imp-event-bottle-option.component.html',
  styleUrls: ['./imp-event-bottle-option.component.scss'],
})
export class ImpEventBottleOptionComponent implements OnInit {

  @Input() option$: Option = DefaultOption;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public table: ImpTableService,
    public bottle: ImpBottleService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
    public popover: PopoverController
  ) { }

  ngOnInit() {
  }

  toggleBottleOptionManager(): void {
    this.auth.viewingEventBottleOptionPopover = !this.auth.viewingEventBottleOptionPopover;
    if (this.auth.viewingEventBottleOptionPopover) {
      this.option.set(this.option$);
      this.presentPopover();
    }
  }

  async presentPopover() {
    this.auth.optionControl = await this.popover.create({
      component: ImpBottleOptionOptionComponent,
      showBackdrop: false,
      animated: true
    });
    this.auth.optionControl.onDidDismiss().then(() => {
      this.auth.viewingEventBottleOptionPopover = false;
    });
    return await this.auth.optionControl.present();
  }
}
