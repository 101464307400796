import { Injectable } from '@angular/core';
import { Ask } from './tdct-ask.model';
import { DefaultAsk, MockAsks } from './tdct-ask.mocks';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TdctAskService {

  $: Ask = DefaultAsk;
  s$: Ask[] = MockAsks;
  all$: Ask[] = MockAsks;
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  doc: AngularFirestoreDocument<Ask>;
  col: AngularFirestoreCollection<Ask>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public afs: AngularFirestore
  ) { }


  async get(id: string): Promise<Ask> {
    const askPath = 'asks/' + id;
    this.doc = this.afs.doc<Ask>(askPath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  set(ask: Ask): void {
    this.$ = ask;
    this.viewing = true;
  }

  async load(): Promise<Ask[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    this.col = await this.afs.collection<Ask>('asks', ref => ref
        .where('complete', '==', false)
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$ = this.s$.map(ask => this.auth.momentCreatedExactly(ask));
      this.s$ = this.s$.map(ask => this.auth.momentLastUpdatedFromNow(ask));
      this.set(this.s$[0]);
      this.slowCount();
    }
    this.loading = false;
    return this.s$;
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    let loadinAsks: Ask[] = [];
    if (this.all$.length === 1) {
      loadinAsks = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadinAsks = this.all$.splice(0, 2);
    } else { loadinAsks = this.all$.splice(0, 3); }
    this.s$.push(...loadinAsks);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleView(): void {
    this.clear();
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      this.set(this.$);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }
}
