import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TdctAuthService } from '../tdct-auth.service';
import { Auth } from '../tdct-auth.model';
import { DefaultAuth } from '../tdct-auth.mocks';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tdct-auth-login-form',
  templateUrl: './tdct-auth-login-form.component.html',
  styleUrls: ['./tdct-auth-login-form.component.scss'],
})
export class TdctAuthLoginFormComponent implements OnInit {
  loginData: Auth = { ...DefaultAuth };
  submitted = false;

  constructor(public auth: TdctAuthService) {}

  ngOnInit() {
    this.auth.$ = DefaultAuth;
  }

  async submit(loginForm: NgForm) {
    this.submitted = true;
    if (loginForm.valid) {
      this.auth.loading = true;
      try {
        await this.auth.afa.signInWithEmailAndPassword(this.loginData.email, this.loginData.password);
      //  this.auth.presentSuccessAlert('Login successful!');
        this.auth.errorMessage = '';
      } catch (error) {
        this.auth.presentErrorAlert(error);
        this.auth.errorMessage = error.message;
        this.auth.successMessage = '';
      } finally {
        this.auth.loading = false;
      }
      this.clearForm();
    }
  }

  clearForm() {
    this.loginData.email = '';
    this.loginData.password = '';
    this.submitted = false;
  }
}
