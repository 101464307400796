import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
})
export class SitemapComponent {
  routes = [
    { name: 'Home', path: '/home', version: 1 },
    { name: 'Login', path: '/login', version: 2 },
    { name: 'Signup', path: '/signup', version: 2 },
    { name: 'Reset Password', path: '/reset', version: 2 },
    { name: 'Cities List', path: '/cities', version: 2 },
    { name: 'City Details', path: '/city/:id', version: 1 },
    { name: 'Admin Manage Dashboard', path: '/admin/manage', version: 1 },
    { name: 'Admin Manage Engine', path: '/admin/manage/engine', version: 1 },
    { name: 'Admin Manage Engine Brand', path: '/admin/manage/engine/brand', version: 1 },
    { name: 'Admin Manage Engine Core', path: '/admin/manage/engine/core', version: 1 },
    { name: 'Admin Manage Events', path: '/admin/manage/events', version: 1 },
    { name: 'Admin Manage Cities', path: '/admin/manage/cities', version: 1 },
    { name: 'Admin Manage Promoter', path: 'admin/manage/promoters', version: 1 },
    { name: 'Admin Manage Venues', path: '/admin/manage/venues', version: 1 },
    { name: 'Admin Manage Users', path: '/admin/manage/users', version: 1 },
    { name: 'User Profile', path: '/user/profile', version: 2 },
    { name: 'User Promoter Details', path: '/user/:userId/promoter/:promoterId', version: 1 },
    { name: 'User Promoter Analytics', path: '/user/:userId/promoter/:promoterId/analytics', version: 1 },
    { name: 'User Promoter Profiles', path: '/user/:userId/promoter/profiles', version: 1 },
    { name: 'User Account', path: '/user/account', version: 2 },
    { name: 'Event Details', path: '/event/:id', version: 1 },
    { name: 'Event Checkout Redirect', path: '/event/redirectFromCheckout/:id', version: 1 },
    { name: 'Event Analytics', path: '/event/:id/analytics', version: 1 },
    { name: 'Featured Events', path: '/events/featured', version: 1 },
    { name: 'Table Details', path: '/table/:id', version: 2 },
    { name: 'Ticket Details', path: '/ticket/:id', version: 2 },
    { name: 'Scanner', path: '/scanner', version: 1 },
    { name: 'Event Scanner', path: '/scanner/promoter/:promoterId/event/:eventId', version: 1 },
    { name: 'Venue Details', path: '/venue/:venueId', version: 1 },
    { name: 'Terms and Conditions', path: '/terms', version: 2 },
    { name: 'Privacy Policy', path: '/privacy', version: 2 },
    { name: 'Order Success', path: '/order/success', version: 1 },
    { name: 'Order Cancel', path: '/order/cancel', version: 1 },
    // { name: 'Main Tabs', path: '/tabs', version: 1 },
  ];

  showVersions = false; // Flag to toggle between name and name with version
  sortedRoutes = this.sortRoutes(); // Initialize sortedRoutes

  constructor(private location: Location, private router: Router) {}

  goBack() {
    this.location.back();
  }

  toggleDisplay() {
    this.showVersions = !this.showVersions;
    this.sortedRoutes = this.sortRoutes(); // Update sortedRoutes on toggle
  }

  private sortRoutes() {
    if (this.showVersions) {
      return this.routes.sort((a, b) => a.version - b.version || a.name.localeCompare(b.name));
    } else {
      return this.routes.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}
