import { Event } from './imp-event.model';
export const ChicagoEvent: Event = {
 DJCosts: 0,
    hostCosts: 0,
    venueCosts: 0,
    TVAds: 0,
    radioAds: 0,
    socialMediaAds: 0,
    otherExpenses: 0,
    DJ: '',
    host: '',
    theme: '',
    venue: '',
    id: '1',
    name: 'Chicago',
    description: 'This isn\'t an event you want to miss',
    link: 'https://imp-events.com/event/1',
    route: 'event/1',
    image: 'assets/img/chicago-event.jpg',
    country: 'US',
    state: 'IL',
    city: 'Chicago',
    street: '3 Bulls Dr',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '2',
    promoterName: 'Jane\'s Promotion Group',
    promoterEmail: 'test-jane-promoter@tdct.io',
    promoterPhoto: 'asset/img-default-profile.png',
    promoterBio: 'Newest promotion group in town',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: 'a few hours ago',
    displayStartTimestamp: '2019-02-13',
    displayEndTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    unixStartTimestamp: 1550109118,
    unixEndTimestamp: 1550110118,
    onlineSalesStartTimestamp: 1550110118,
    onlineFollowerSalesStartTimestamp: 1550110118,
    onlineSalesEndTimestamp: 4796668800,
    onlineEndTimestamp: 4796668800,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    freeMaleWristbandsScanned: 0,
    freeFemaleWristbandsScanned: 0,
    totalMaleWristbandsScanned: 0,
    totalFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    price: 0,
    ticketCount: 0,
    tableCount: 0,
    bottleCount: 0,
    ticketOptionCount: 1,
    tableOptionCount: 3,
    bottleOptionCount: 3,
    zipcode: '60007',
    cities: [],
    category: "",
    tags: ['approved'],
    displayApprovalTimestamp: '2019-02-13',
    unixApprovalTimestamp: 1550109118,
};
export const DetroitEvent: Event = {
 DJCosts: 0,
    hostCosts: 0,
    category: "",
    venueCosts: 0,
    TVAds: 0,
    radioAds: 0,
    socialMediaAds: 0,
    otherExpenses: 0,
    DJ: '',
    host: '',
    theme: '',
    venue: '',
    id: '2',
    name: 'Detroit',
    description: 'Motown Chronicles',
    link: 'https://imp-events.com/event/2',
    route: 'event/2',
    image: 'assets/img/detroit-event.jpeg',
    country: 'US',
    state: 'MI',
    city: 'Detroit',
    street: '1 Jefferson Ave',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '2',
    promoterName: 'Jane\'s Promotion Group',
    promoterEmail: 'test-jane-promoter@tdct.io',
    promoterPhoto: 'asset/img-default-profile.png',
    promoterBio: 'Newest promotion group in town',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: 'a few hours ago',
    displayStartTimestamp: '2019-02-13',
    displayEndTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    unixStartTimestamp: 1550109118,
    unixEndTimestamp: 1550110118,
    onlineSalesEndTimestamp: 1550110118,
    onlineSalesStartTimestamp: 1550110118,
    onlineFollowerSalesStartTimestamp: 1550110118,
    onlineEndTimestamp: 1550110118,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    freeMaleWristbandsScanned: 0,
    freeFemaleWristbandsScanned: 0,
    totalMaleWristbandsScanned: 0,
    totalFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    price: 10,
    ticketCount: 0,
    tableCount: 0,
    bottleCount: 0,
    ticketOptionCount: 1,
    tableOptionCount: 3,
    bottleOptionCount: 3,
    zipcode: '60007',
    cities: [],
    tags: ['featured']
};
export const MiamiEvent: Event = {
  DJCosts: 0,
    hostCosts: 0,
    venueCosts: 0,
    TVAds: 0,
    category: "",
    radioAds: 0,
    socialMediaAds: 0,
    otherExpenses: 0,
    DJ: '',
    host: '',
    theme: '',
    venue: '',
    id: '3',
    name: 'Miami',
    description: 'A South Beach Experience',
    link: 'https://imp-events.com/event/3',
    route: 'event/3',
    image: 'assets/img/miami-event.png',
    country: 'US',
    state: 'FL',
    city: 'Miami',
    street: '2 James Rd',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '2',
    promoterName: 'Jane\'s Promotion Group',
    promoterEmail: 'test-jane-promoter@tdct.io',
    promoterPhoto: 'asset/img-default-profile.png',
    promoterBio: 'Newest promotion group in town',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: 'a few hours ago',
    displayStartTimestamp: '2019-02-13',
    displayEndTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    unixStartTimestamp: 1550109118,
    unixEndTimestamp: 1550110118,
    onlineSalesEndTimestamp: 1550110118,
    onlineSalesStartTimestamp: 1550110118,
    onlineFollowerSalesStartTimestamp: 1550110118,
    onlineEndTimestamp: 1550110118,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    freeMaleWristbandsScanned: 0,
    freeFemaleWristbandsScanned: 0,
    totalMaleWristbandsScanned: 0,
    totalFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    price: 3,
    ticketCount: 0,
    tableCount: 0,
    bottleCount: 0,
    ticketOptionCount: 1,
    tableOptionCount: 3,
    bottleOptionCount: 3,
    zipcode: '60007',
    cities: [],
    tags: ['flagged']
};
export const RichmondEvent: Event = {
    category: "",
   DJCosts: 0,
    hostCosts: 0,
    venueCosts: 0,
    TVAds: 0,
    radioAds: 0,
    socialMediaAds: 0,
    otherExpenses: 0,
    DJ: '',
    host: '',
    theme: '',
    venue: '',
    id: '4',
    name: 'Richmond',
    description: 'Virginia Capital',
    link: 'https://imp-events.com/event/3',
    route: 'event/3',
    image: 'assets/img/richmond-event.jpeg',
    country: 'US',
    state: 'VA',
    city: 'Richmond',
    street: '1 James Rd',
    userId: '2',
    userEmail: 'test-jane@tdct.io',
    userName: 'Jane',
    userPhoto: 'assets/img/default-profile.png',
    promoterId: '2',
    promoterName: 'Jane\'s Promotion Group',
    promoterEmail: 'test-jane-promoter@tdct.io',
    promoterPhoto: 'asset/img-default-profile.png',
    promoterBio: 'Newest promotion group in town',
    displayTimestamp: '2019-02-13',
    displayLastUpdateTimestamp: 'a few days ago',
    displayStartTimestamp: '2019-02-13',
    displayEndTimestamp: '2019-02-13',
    unixTimestamp: 1566664900,
    unixLastUpdateTimestamp: 1566664900,
    unixStartTimestamp: 1550109118,
    unixEndTimestamp: 1550110118,
    onlineSalesEndTimestamp: 1550110118,
    onlineSalesStartTimestamp: 1550110118,
    onlineEndTimestamp: 1550110118,
    onlineFollowerSalesStartTimestamp: 1550110118,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    freeMaleWristbandsScanned: 0,
    freeFemaleWristbandsScanned: 0,
    totalMaleWristbandsScanned: 0,
    totalFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    price: 30,
    ticketCount: 0,
    tableCount: 0,
    bottleCount: 0,
    ticketOptionCount: 1,
    tableOptionCount: 3,
    bottleOptionCount: 3,
    zipcode: '60007',
    cities: [],
    tags: ['private']
};
export const MockEvents: Event[] = [ChicagoEvent, DetroitEvent, MiamiEvent, RichmondEvent];
export const DefaultEvent: Event = {
    DJCosts: 0,
    hostCosts: 0,
    venueCosts: 0,
    category: "",
    TVAds: 0,
    radioAds: 0,
    socialMediaAds: 0,
    otherExpenses: 0,
    DJ: '',
    host: '',
    theme: '',
    venue: '',
    id: '',
    name: '',
    description: '',
    image: '',
    country: 'US',
    state: '',
    city: '',
    street: '',
    link: '',
    route: '',
    userId: '',
    userEmail: '',
    userName: '',
    userPhoto: '',
    promoterId: '',
    promoterName: '',
    promoterEmail: '',
    promoterPhoto: '',
    promoterBio: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    displayStartTimestamp: '',
    displayEndTimestamp: '',
    unixTimestamp: 0,
    unixLastUpdateTimestamp: 0,
    unixStartTimestamp: 0,
    unixEndTimestamp: 0,
    onlineSalesEndTimestamp: 1550110118,
    onlineSalesStartTimestamp: 1550110118,
    onlineFollowerSalesStartTimestamp: 1550110118,
    onlineEndTimestamp: 1550110118,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    freeMaleWristbandsScanned: 0,
    freeFemaleWristbandsScanned: 0,
    totalMaleWristbandsScanned: 0,
    totalFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    price: 0,
    ticketCount: 0,
    tableCount: 0,
    bottleCount: 0,
    ticketOptionCount: 0,
    tableOptionCount: 0,
    bottleOptionCount: 0,
    zipcode: '',
    currentMaleTicketPrice: undefined,
    currentFemaleTicketPrice: undefined,
    cities: [],
    tags: [],
};
