import { Component, OnInit, Input,  } from '@angular/core';
import { Ticket } from './imp-ticket.model';
import { DefaultTicket } from './imp-ticket.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpTicketService } from './imp-ticket.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { PopoverController } from '@ionic/angular';
import { ImpOptionService } from '../imp-option/imp-option.service';


@Component({
  selector: 'app-imp-ticket',
  templateUrl: './imp-ticket.component.html',
  styleUrls: ['./imp-ticket.component.scss'],
})
export class ImpTicketComponent implements OnInit {

  @Input() ticket$: Ticket = DefaultTicket;
  eventRoute: string = "";

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public ticket: ImpTicketService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public popover: PopoverController
  ) { }

  ngOnInit() { }

  ngOnChanges() {
    this.eventRoute = `/event/${this.ticket$.eventId}`;
  }

  toggleTicketOption(action: string) {
    this.ticket.set(this.ticket$);
    this.presentPopover();
  }

  async presentPopover() {
  }

}
