import { Component, OnInit, ViewChild, ElementRef,  } from "@angular/core";
import { Chart } from "chart.js";
import { TdctAuthService } from '../../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user.service';
import { TdctUserPromoterService } from '../tdct-user-promoter.service';
import { ImpEventService } from '../../../imp-event/imp-event.service';
import { ImpTicketService } from '../../../imp-ticket/imp-ticket.service';
import { ImpTableService } from '../../../imp-table/imp-table.service';
import { ImpBottleService } from '../../../imp-bottle/imp-bottle.service';
import { TdctAdminService } from '../../../tdct-admin/tdct-admin.service';


@Component({
  selector: 'app-tdct-user-promoter-analytics',
  templateUrl: './tdct-user-promoter-analytics.component.html',
  styleUrls: ['./tdct-user-promoter-analytics.component.scss'],
})
export class TdctUserPromoterAnalyticsComponent implements OnInit {

  @ViewChild("barCanvas", { static: true }) barCanvas: ElementRef;
  @ViewChild("doughnutCanvas", { static: true }) doughnutCanvas: ElementRef;
  viewingEventRevenue: boolean = true;
  viewingEventAttendees: boolean = true;

  private barChart: Chart;
  private doughnutChart: Chart;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public ticket: ImpTicketService,
    public table: ImpTableService,
    public bottle: ImpBottleService,
    public admin: TdctAdminService
  ) { }

  toggleEventRevenue(): void {
    this.viewingEventRevenue = !this.viewingEventRevenue;
  }

  toggleEventAttendees(): void {
    this.viewingEventAttendees = !this.viewingEventAttendees
  }

  ngOnInit() {
    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: "bar",
      data: {
        labels: ["Tickets", "Tables", "Bottles"],
        datasets: [
          {
            data: [this.promoter.$.ticketDoorRevenue, this.promoter.$.tableOrderRevenue, this.promoter.$.bottleOrderRevenue],
            backgroundColor: [
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              "rgba(255, 159, 64, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return '$' + value;
                  }
                }
              }
            }
          ]
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
            title: () => null,
          }
        },
      }
    });

    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: "doughnut",
      data: {
        labels: ["Females", "Males"],
        datasets: [
          {
            data: [this.promoter.$.paidFemaleWristbandsScanned, this.promoter.$.paidMaleWristbandsScanned],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            hoverBackgroundColor: ["#FF6384", "#36A2EB"]
          }
        ]
      }
    });
  }
}
