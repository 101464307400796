import { Component, OnInit, OnDestroy } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { ImpEventService } from '../imp-event.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import * as moment from 'moment';
import { DefaultEvent } from '../imp-event.mocks';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpCityService } from '../../imp-city/imp-city.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { EVENT_CATEGORIES } from '../../../../environments/event-categories';
import { TdctUploadService } from '../../tdct-upload/tdct-upload.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Component({
    selector: 'app-imp-event-creator-form',
    templateUrl: './imp-event-creator-form.component.html',
    styleUrls: ['./imp-event-creator-form.component.scss'],
})
export class ImpEventCreatorFormComponent implements OnInit, OnDestroy {

    eventData: any; // Input to receive the event data when editing
    eventCreatorForm: FormGroup;
    submitted = false;
    startTime: string;
    endTime: string;
    startTimeFocused: boolean = false;
    startTimestamped: boolean = false;
    endTimeFocused: boolean = false;
    endTimestamped: boolean = false;
    eventCategories = EVENT_CATEGORIES;
    isEditingEvent = false;

    constructor(
        public auth: TdctAuthService,
        public user: TdctUserService,
        public event: ImpEventService,
        public promoter: TdctUserPromoterService,
        public admin: TdctAdminService,
        public city: ImpCityService,
        public formBuilder: FormBuilder,
        public popover: PopoverController,
        public upload: TdctUploadService,
        public router: Router,
        public alertController: AlertController
    ) { }

    ngOnInit() {
        this.initializeForm();
        this.loadCitiesAvailable();  // Ensure cities are loaded

        // If event data is passed, preload the form with event data
        if (this.eventData) {
            this.patchFormWithEventData(this.eventData);
        } else {
            this.initializeTime(); // Initialize with default time if no data
        }
    }

    // Method to patch form with existing event data
    patchFormWithEventData(eventData: any) {
        console.log('Patching Event Category:', eventData.eventCategory);
        console.log('Patching Event Category:', eventData.eventCity);
        console.log('Patching Event Category:', eventData.city);


        this.eventCreatorForm.patchValue({
            eventName: eventData.name,
            eventDescription: eventData.description,
            eventCity: eventData.city,
            eventVenue: eventData.venue,
            eventCategory: eventData.eventCategory || 'Other', // Fallback to 'Other' if undefined
            eventTheme: eventData.theme,
            eventHost: eventData.host,
            eventDJ: eventData.DJ,
            currentMaleTicketPrice: eventData.currentMaleTicketPrice,
            currentFemaleTicketPrice: eventData.currentFemaleTicketPrice,
            socialMediaAds: eventData.socialMediaAds,
            radioAds: eventData.radioAds,
            TVAds: eventData.TVAds,
            venueCosts: eventData.venueCosts,
            hostCosts: eventData.hostCosts,
            DJCosts: eventData.DJCosts,
            otherExpenses: eventData.otherExpenses,
            startTime: moment.unix(eventData.unixStartTimestamp).toISOString(),
            endTime: moment.unix(eventData.unixEndTimestamp).toISOString(),
        });
    }

    ngOnDestroy(): void {
        this.auth.uploadingEventImage = false;
        this.auth.imageUploaded = false;
        this.event.editing = false;
    }

    ionViewWillEnter() {
        if (!this.auth.editingEvent) {
            this.event.$ = DefaultEvent;
        }
        this.loadCitiesAvailable();
        this.initializeTime();
    }

    async loadCitiesAvailable() {
        try {
            await this.city.loadAllCities();  // Load all cities
        } catch (error) {
            console.error('Error loading cities:', error);
        }
    }

    initializeForm() {
        this.eventCreatorForm = this.formBuilder.group({
            eventName: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]],
            eventDescription: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(2500)]],
            eventCity: ['', [Validators.required]],
            eventVenue: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(500)]],
            eventCategory: ['', [Validators.required]],
            eventTheme: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
            eventHost: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
            eventDJ: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
            currentMaleTicketPrice: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            currentFemaleTicketPrice: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            socialMediaAds: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            radioAds: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            TVAds: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            venueCosts: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            hostCosts: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            DJCosts: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            otherExpenses: [0, [Validators.pattern(/^\d*(?:\.\d{1,2})?$/)]],
            startTime: ['', Validators.required],
            endTime: ['', Validators.required],
            country: 'US',
        });
    }

    // Initialize the start and end time if no event data is provided
    initializeTime(): void {
        if (!this.eventData) {
            const unixStart = moment().unix();
            this.startTime = moment.unix(unixStart).toISOString();
            this.endTime = moment.unix(unixStart + 1800).toISOString();
        }
    }

    async submit(eventCreatorForm: FormGroup) {
        this.submitted = true;

        if (eventCreatorForm.valid) {
            try {
                this.mapFormValuesToEvent();
                await this.createEvent();
                await this.event.load();

                // If event creation is successful, show success alert
                await this.presentSuccessAlert();

                // Navigate to the promoter's events page
                this.router.navigate(['/user/profile/promoter/events']);
            } catch (error) {
                // Log the error and show an alert if the event creation fails
                console.error("Error during event creation:", error);
                await this.auth.presentErrorAlert({
                    code: 'Error',
                    message: `Error creating event: ${error.message || error}`
                });
            }
        } else {
            console.warn("Form is invalid. Please fill in all required fields correctly.");
        }
    }

    async presentSuccessAlert() {
        const alert = await this.alertController.create({
            header: 'Success',
            message: 'The event has been added successfully!',
            buttons: ['OK']
        });

        await alert.present();
    }

    mapFormValuesToEvent(): void {
        this.event.$.name = this.eventCreatorForm.get('eventName')?.value;
        this.event.$.description = this.eventCreatorForm.get('eventDescription')?.value;
        this.event.$.city = this.eventCreatorForm.get('eventCity')?.value;
        this.event.$.venue = this.eventCreatorForm.get('eventVenue')?.value;
        this.event.$.category = this.eventCreatorForm.get('eventCategory')?.value;
        this.event.$.theme = this.eventCreatorForm.get('eventTheme')?.value;
        this.event.$.host = this.eventCreatorForm.get('eventHost')?.value;
        this.event.$.DJ = this.eventCreatorForm.get('eventDJ')?.value;
        this.event.$.currentMaleTicketPrice = this.eventCreatorForm.get('currentMaleTicketPrice')?.value;
        this.event.$.currentFemaleTicketPrice = this.eventCreatorForm.get('currentFemaleTicketPrice')?.value;
        this.event.$.socialMediaAds = this.eventCreatorForm.get('socialMediaAds')?.value;
        this.event.$.radioAds = this.eventCreatorForm.get('radioAds')?.value;
        this.event.$.TVAds = this.eventCreatorForm.get('TVAds')?.value;
        this.event.$.venueCosts = this.eventCreatorForm.get('venueCosts')?.value;
        this.event.$.hostCosts = this.eventCreatorForm.get('hostCosts')?.value;
        this.event.$.DJCosts = this.eventCreatorForm.get('DJCosts')?.value;
        this.event.$.otherExpenses = this.eventCreatorForm.get('otherExpenses')?.value;
        this.event.$.unixStartTimestamp = moment(this.eventCreatorForm.get('startTime')?.value).unix();
        this.event.$.unixEndTimestamp = moment(this.eventCreatorForm.get('endTime')?.value).unix();
        this.event.$.country = "US";

        // Stamp Promoter
        this.stampPromoter();

        // Set the image link, only if the upload is successful
        const imageLink = this.getEventImageLink();
        if (imageLink) {
            this.event.$.image = imageLink;
            console.log('Event image link set:', this.event.$.image);
        } else {
            console.warn('No image link available to set for the event.');
        }
    }

    getEventImageLink(): string | null {
        return this.upload.getUploadLink('uploadingEventImage');
    }



    async createEvent() {
        this.auth.loading = true;

        try {
            // Log that we are starting the event creation process
            console.log("Starting event creation process...");

            // Attempt to create the event
            await this.event.create();

            // Log a message indicating the event creation has completed
            console.log("Event creation completed in Firestore:", this.event.$);

            // Update counts if this is a new event
            if (!this.auth.editingEvent) {
                ++this.user.$.eventCount;
                ++this.event.count;
                if (this.auth.userAdmin) {
                    ++this.user.$.eventTotal;
                }

                // Log the updated counts
                console.log("User event counts updated:", {
                    eventCount: this.user.$.eventCount,
                    totalEventCount: this.event.count,
                    adminTotal: this.user.$.eventTotal
                });
            }
        } catch (error) {
            // Log the error encountered during event creation
            console.error("Error in createEvent method:", error);

            // Re-throw the error to be handled by the calling method
            throw error;
        } finally {
            this.auth.loading = false;
            this.auth.viewingEventCreatorForm = false;
            this.auth.viewingUserPromoterEventCreatorForm = false;
            this.auth.editingEvent = false;

            // Attempt to dismiss the popover, and catch any errors in case it's not open
            try {
                await this.popover.dismiss();
                console.log("Popover dismissed successfully.");
            } catch (dismissError) {
                console.warn("Error dismissing popover (it may not have been open):", dismissError);
            }

            // Attempt to reload the event list after creation
            try {
                await this.event.load();
                console.log("Event list reloaded successfully after event creation.");
            } catch (loadError) {
                console.error("Error reloading event list after creation:", loadError);
            }
        }
    }

    async cancelEdit() {
        this.auth.editingEvent = false;
        this.auth.viewingEventCreatorForm = false;
        this.auth.viewingUserPromoterEventCreatorForm = false;

        if (this.auth.viewingPromoterEvents) {
            await this.event.load();
        }
        await this.popover.dismiss();
    }

    // Not saving properly :/
    stampPromoter(): void {
        this.event.$.promoterId = this.promoter.$.id;
        this.event.$.promoterEmail = this.promoter.$.email;
        this.event.$.promoterName = this.promoter.$.name;
        this.event.$.promoterPhoto = this.promoter.$.photo;
        this.event.$.promoterBio = this.promoter.$.bio;
        console.log("PROMOTER STAMPED");
    }

    toggleEventImageChange(): void {
        // Toggle uploading state
        this.auth.uploadingEventImage = !this.auth.uploadingEventImage;

        if (this.auth.uploadingEventImage) {
            // Trigger file input for selecting image
            const fileInput = document.getElementById('file-input') as HTMLInputElement;
            if (fileInput) {
                fileInput.click();  // Open file dialog
            }
        } else if (this.auth.imageUploaded) {
            // Once upload is complete, set the image link
            this.event.$.image = this.upload.getUploadLink('uploadingEventImage');
        }
    }

    onFileSelected(event: any): void {
        const file = event.target.files[0];  // Get the selected file

        if (file) {
            this.auth.uploadingEventImage = true;  // Set the upload state to true

            // Set the file object in the upload service
            this.upload.object = file;

            // Call the imageToStorage method to upload the file
            this.upload.imageToStorage().then((uploadUrl: string) => {
                this.auth.imageUploaded = true;
                this.auth.uploadingEventImage = false;  // Upload finished

                // Update event with the uploaded image link
                this.event.$.image = uploadUrl;
                console.log('Image uploaded and event updated with image:', this.event.$.image);
            }).catch((error) => {
                console.error('Error uploading image:', error);
                this.auth.uploadingEventImage = false;
            });
        }
    }



    startTimeInFocus() {
        this.startTimeFocused = true;
    }

    startTimeChanged(event) {
        if (this.startTimeFocused) {
            this.startTimestamped = true;
            this.event.$.unixStartTimestamp = moment(event.detail.value).unix();
            this.event.$.unixEndTimestamp = this.event.$.unixStartTimestamp + 1800;
            this.eventCreatorForm.patchValue({ endTime: moment.unix(this.event.$.unixEndTimestamp).toISOString() });
        }
    }

    endTimeInFocus() {
        this.endTimeFocused = true;
    }

    endTimeChanged(event) {
        if (this.endTimeFocused) {
            this.endTimestamped = true;
            this.event.$.unixEndTimestamp = moment(event.detail.value).unix();
        }
    }

    onCityChange() {
        console.log(this.event.$);
    }
}
