import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImpTableService } from '../imp-table.service';
import { ImpTableDetailComponent } from '../imp-table-detail/imp-table-detail.component';
import { Table } from '../imp-table.model';

// Corrected paths based on the structure
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';

@Component({
  selector: 'app-imp-table-controller',
  templateUrl: './imp-table-controller.component.html',
  styleUrls: ['./imp-table-controller.component.scss'],
})
export class ImpTableControllerComponent implements OnInit {

  userTables: Table[] = []; 
  itemsToShow = 10;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public theme: ImpThemeService,
    public table: ImpTableService,
    private modalCtrl: ModalController // Inject ModalController
  ) { }

  ngOnInit() {
    const userId = this.user.$.id;
    this.loadUserTables(userId);
  }

  showMore() {
    this.itemsToShow += 10; 
  }

  async loadUserTables(userId: string) {
    try {
      this.userTables = await this.table.getTablesByUserId(userId);
      console.log('User Tables:', this.userTables);
    } catch (error) {
      console.error('Error loading user tables:', error);
    }
  }


  toggleTableView() {
    this.table.viewing = !this.table.viewing;
  }

  async openTableModal(table: Table) {
    const modal = await this.modalCtrl.create({
      component: ImpTableDetailComponent,
      componentProps: { table: table } // Pass the table data to the modal
    });

    return await modal.present();
  }
}
