import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpBottleService } from '../imp-bottle.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { Option } from '../../imp-option/imp-option.model';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { ImpEventService } from '../../imp-event/imp-event.service';


@Component({
  selector: 'app-imp-bottle-option-editor-form',
  templateUrl: './imp-bottle-option-editor-form.component.html',
  styleUrls: ['./imp-bottle-option-editor-form.component.scss'],
})
export class ImpBottleOptionEditorFormComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public promotor: TdctUserPromoterService,
    public event: ImpEventService,
    public bottle: ImpBottleService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public afs: AngularFirestore
  ) { }

  ngOnInit() { }

  toggleBottleOptionImageChange(): void {
    this.auth.uploadingBottleOptionImage = !this.auth.uploadingBottleOptionImage;
  }

  async submit(bottleOptionForm) {
    this.submitted = true;
    this.auth.loading = true;
    this.stamp(bottleOptionForm);
    await this.editOption();
    this.auth.viewingBottleOptionEditorForm = false;
    this.auth.loading = false;
  }

  stamp(bottleOptionForm: Option) {
    this.option.$.price = bottleOptionForm.price;
    this.option.$.name = bottleOptionForm.name || this.option.$.name;
    this.option.$.description = bottleOptionForm.description || this.option.$.description;
    this.option.$.description = this.option.$.description.replace(/(\r\n\t|\n|\r\t)/gm, "");
  }

  async editOption() {
    try {
      await this.afs.doc(`events/${this.option.$.eventId}/options/${this.option.$.id}`).update({
        price: this.option.$.price,
        name: this.option.$.name,
        image: this.option.$.image,
        description: this.option.$.description,
        unixLastUpdateTimestamp: moment().unix()
      });
    } catch (error) {
      this.auth.presentErrorAlert(error);
    }
  }

}
