import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { ImpCityService } from '../imp-city.service';


@Component({
  selector: 'app-imp-city-controller',
  templateUrl: './imp-city-controller.component.html',
  styleUrls: ['./imp-city-controller.component.scss'],
})
export class ImpCityControllerComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public theme: ImpThemeService,
    public city: ImpCityService
  ) { }

  ngOnInit() {
    console.log('[imp-city-controller.ngOnInit] Hello World!');
  }

  toggleCityCreation(): void {
    this.auth.viewingCityCreatorForm = !this.auth.viewingCityCreatorForm;
  }

}
