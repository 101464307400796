export default {
    production: {
        stripePublishableKeyTest: 'pk_test_ZSrFnMRNZDWqutTxYuR50xxK', // Test Key
        stripePublishableKeyLive: 'pk_live_uuqGxuuxmnwNAkBtMr3EGzUs', // Live Key
        
        //prod build uses live key
        stripePublishableKey:     'pk_live_uuqGxuuxmnwNAkBtMr3EGzUs', // Whichever key you want use
    },
    dev: {
        stripePublishableKeyTest: 'pk_test_ZSrFnMRNZDWqutTxYuR50xxK', // Test Key
        stripePublishableKeyLive: 'pk_live_uuqGxuuxmnwNAkBtMr3EGzUs', // Live Key

         //dev build uses test key
        stripePublishableKey:     'pk_test_ZSrFnMRNZDWqutTxYuR50xxK', // Whichever key you want use

    },
    uat: {
        stripePublishableKeyTest: 'pk_test_ZSrFnMRNZDWqutTxYuR50xxK', // Test Key
        stripePublishableKeyLive: 'pk_live_uuqGxuuxmnwNAkBtMr3EGzUs', // Live Key

         //uat build uses test key
        stripePublishableKey:     'pk_test_ZSrFnMRNZDWqutTxYuR50xxK', // Whichever key you want use

    }
};
