import { Component, OnInit, Output, EventEmitter,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../tdct-admin.service';
import { TdctDeviceService } from '../../tdct-device/tdct-device.service';
import { TdctUploadService } from '../../tdct-upload/tdct-upload.service';
import { TdctPostService } from '../../tdct-post/tdct-post.service';
import { TdctAskService } from '../../tdct-ask/tdct-ask.service';
import { TdctTaskService } from '../../tdct-task/tdct-task.service';
import { TdctPingService } from '../../tdct-ping/tdct-ping.service';
import { TdctMessageService } from '../../tdct-message/tdct-message.service';
import { Router } from '@angular/router';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';


@Component({
  selector: 'app-tdct-admin-core-engine',
  templateUrl: './tdct-admin-core-engine.component.html',
  styleUrls: ['./tdct-admin-core-engine.component.scss'],
})
export class TdctAdminCoreEngineComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public device: TdctDeviceService,
    public upload: TdctUploadService,
    public post: TdctPostService,
    public ask: TdctAskService,
    public task: TdctTaskService,
    public ping: TdctPingService,
    public message: TdctMessageService,
    public promoter: TdctUserPromoterService,
    public admin: TdctAdminService,
    public router: Router
  ) { }

  ngOnInit() { }

  async toggleUsers() {
    this.admin.$.controllingUsers = !this.admin.$.controllingUsers;
    if (this.admin.$.controllingUsers) {
      this.auth.viewingController = true;
      this.user.load();
    } else {
      this.auth.viewingController = false;
      this.user.sanitize();
      this.promoter.sanitize();
    }
  }

  clearUserCreatorForm(): void {
    this.auth.viewingUserCreatorForm = false;
  }

  async toggleDevices() {
    this.admin.$.controllingDevices = !this.admin.$.controllingDevices;
  }

  async toggleUploads() {
    this.admin.$.controllingUploads = !this.admin.$.controllingUploads;
  }

  async togglePosts() {
    this.admin.$.controllingPosts = !this.admin.$.controllingPosts;
  }

  async toggleAsks() {
    this.admin.$.controllingAsks = !this.admin.$.controllingAsks;
  }

  async toggleTasks() {
    this.admin.$.controllingTasks = !this.admin.$.controllingTasks;
  }

  async togglePings() {
    this.admin.$.controllingPings = !this.admin.$.controllingPings;
  }

  async toggleMessages() {
    this.admin.$.controllingMessages = !this.admin.$.controllingMessages;
  }

}