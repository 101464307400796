import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ImpEventService } from "../imp-event/imp-event.service";
import moment from "moment";
import { ImpEventDetailComponent } from "../imp-event/imp-event-detail/imp-event-detail.component";
import { ModalController } from "@ionic/angular";
import { ImpCityService } from "../imp-city/imp-city.service";

@Component({
  selector: "app-event-details",
  templateUrl: "./event-details.component.html",
  styleUrls: ["./event-details.component.scss"],
})
export class EventDetailsComponent implements OnInit {
  eventId: string;
  eventData: any = {};
  cityName: string;
  cityState: string;

  constructor(
    private route: ActivatedRoute,
    private eventService: ImpEventService,
    public modalController: ModalController,
    public city: ImpCityService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.eventId = params["id"];
      console.log("this.eventId", this.eventId);
      this.loadEventDetails(this.eventId);
    });
  }

  formatDate(date: string): string {
    const inputFormat = "dddd, MMMM D, YYYY [at] h:mm A";
    const parsedDate = moment(date, inputFormat);
    return parsedDate.format("MMM D, YYYY, h:mm a");
  }
  async loadEventDetails(id: string) {
    try {
      this.eventData = await this.eventService.getEventById(id);
        console.log("this.eventData", this.eventData);
        this.loadCityDetails(this.eventData.city);
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  }

  async loadCityDetails(cityId: string) {
    try {
      const cityDetails = await this.city.getCityById(cityId); 
      if (cityDetails) {
        this.cityName = cityDetails.name; 
        this.cityState = cityDetails.state; 
      } else {
        console.warn("No city details found for cityId:", cityId);
      }
    } catch (error) {
      console.error("Error fetching city details:", error);
    }
  }

  async openEventDetailsModal(eventData) {
    if (!eventData) {
      console.error("No event data provided to modal");
      return;
    }

    const modal = await this.modalController.create({
      component: ImpEventDetailComponent,
      componentProps: {
        event$: eventData,
      },
    });
    return await modal.present();
  }
}
