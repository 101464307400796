export const EVENT_CATEGORIES = [
    {
        name: "Club/Bars/Lounges/Events",
        description: "Events held in nightlife venues such as clubs, bars, and lounges. This includes DJ nights, parties, and special event nights."
    },
    {
        name: "Event Center Events",
        description: "Events organized at event centers, including conventions, expos, and large gatherings."
    },
    {
        name: "Concerts",
        description: "Live music performances, ranging from small gigs to large-scale concerts."
    },
    {
        name: "Festivals",
        description: "Celebratory events that include music festivals, cultural festivals, and other large gatherings."
    },
    {
        name: "Sporting Event",
        description: "Sports-related events including matches, tournaments, and exhibitions."
    },
    {
        name: "Theater Play/Movie Premieres",
        description: "Live theater performances and movie premiere events."
    },
    {
        name: "Video Gaming Tournaments",
        description: "Competitive gaming events, including esports tournaments."
    },
    {
        name: "Seminars",
        description: "Educational events, workshops, and seminars across various topics."
    },
    {
        name: "Fundraising",
        description: "Fundraising events for charities, non-profits, and causes."
    },
    {
        name: "Political Events",
        description: "Events related to political activities, campaigns, and public forums."
    },
    {
        name: "Trade Shows",
        description: "Events focusing on trade, commerce, and industry exhibitions."
    },
    {
        name: "Networking Events",
        description: "Events aimed at professional networking and industry connections."
    },
    {
        name: "Corporate Events",
        description: "Business-related events such as conferences, corporate meetings, and company gatherings."
    },
    {
        name: "Private Events",
        description: "Personal events including private parties, weddings, and family gatherings."
    },
    {
        name: "Other",
        description: "Any events that do not fit into the above categories."
    }
];
