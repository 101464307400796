import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-message',
  templateUrl: './tdct-message.component.html',
  styleUrls: ['./tdct-message.component.scss'],
})
export class TdctMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
