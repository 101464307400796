import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TdctAuthComponent } from '../home/tdct-auth/tdct-auth.component';
import { TdctUserComponent } from '../home/tdct-user/tdct-user.component';
import { TdctDeviceComponent } from '../home/tdct-device/tdct-device.component';
import { TdctUploadComponent } from '../home/tdct-upload/tdct-upload.component';
import { TdctPostComponent } from '../home/tdct-post/tdct-post.component';
import { TdctAskComponent } from '../home/tdct-ask/tdct-ask.component';
import { TdctTaskComponent } from '../home/tdct-task/tdct-task.component';
import { TdctPingComponent } from '../home/tdct-ping/tdct-ping.component';
import { TdctMessageComponent } from '../home/tdct-message/tdct-message.component';
import { TdctAdminComponent } from '../home/tdct-admin/tdct-admin.component';
import { ImpEventComponent } from '../home/imp-event/imp-event.component';
import { ImpCityComponent } from '../home/imp-city/imp-city.component';
import { ImpTicketComponent } from '../home/imp-ticket/imp-ticket.component';
import { ImpTableComponent } from '../home/imp-table/imp-table.component';
import { ImpTableDetailComponent } from '../home/imp-table/imp-table-detail/imp-table-detail.component';
import { ImpBottleComponent } from '../home/imp-bottle/imp-bottle.component';
import { ImpVenueComponent } from '../home/imp-venue/imp-venue.component';
import { TdctAuthLoginFormComponent } from '../home/tdct-auth/tdct-auth-login-form/tdct-auth-login-form.component';
import { TdctAuthSignupFormComponent } from '../home/tdct-auth/tdct-auth-signup-form/tdct-auth-signup-form.component';
import { TdctAuthResetFormComponent } from '../home/tdct-auth/tdct-auth-reset-form/tdct-auth-reset-form.component';
import { TdctUserCreatorFormComponent } from '../home/tdct-user/tdct-user-creator-form/tdct-user-creator-form.component';
import { TdctUserEditorFormComponent } from '../home/tdct-user/tdct-user-editor-form/tdct-user-editor-form.component';
import { TdctUserEmailFormComponent } from '../home/tdct-user/tdct-user-email-form/tdct-user-email-form.component';
import { TdctUserPasswordFormComponent } from '../home/tdct-user/tdct-user-password-form/tdct-user-password-form.component';
import { TdctAdminCoreEngineComponent } from '../home/tdct-admin/tdct-admin-core-engine/tdct-admin-core-engine.component';
import { TdctAdminBrandEngineComponent } from '../home/tdct-admin/tdct-admin-brand-engine/tdct-admin-brand-engine.component';
import { ImpEventControllerComponent } from '../home/imp-event/imp-event-controller/imp-event-controller.component';
import { ImpCityControllerComponent } from '../home/imp-city/imp-city-controller/imp-city-controller.component';
import { ImpTicketControllerComponent } from '../home/imp-ticket/imp-ticket-controller/imp-ticket-controller.component';
import { ImpTableControllerComponent } from '../home/imp-table/imp-table-controller/imp-table-controller.component';
import { ImpBottleControllerComponent } from '../home/imp-bottle/imp-bottle-controller/imp-bottle-controller.component';
import { ImpVenueControllerComponent } from '../home/imp-venue/imp-venue-controller/imp-venue-controller.component';
import { ImpEventCreatorFormComponent } from '../home/imp-event/imp-event-creator-form/imp-event-creator-form.component';
import { ImpEventOptionComponent } from '../home/imp-event/imp-event-option/imp-event-option.component';
import { ImpEventEditorFormComponent } from '../home/imp-event/imp-event-editor-form/imp-event-editor-form.component';
import { TdctUserOptionComponent } from '../home/tdct-user/tdct-user-option/tdct-user-option.component';
import { ImpOptionComponent } from '../home/imp-option/imp-option.component';
import { ImpThemeComponent } from '../home/imp-theme/imp-theme.component';
import { TdctUserPromoterCreatorFormComponent } from '../home/tdct-user/tdct-user-promoter-creator-form/tdct-user-promoter-creator-form.component';
import { TdctUserControllerComponent } from '../home/tdct-user/tdct-user-controller/tdct-user-controller.component';
import { TdctDeviceControllerComponent } from '../home/tdct-device/tdct-device-controller/tdct-device-controller.component';
import { TdctUploadControllerComponent } from '../home/tdct-upload/tdct-upload-controller/tdct-upload-controller.component';
import { TdctPostControllerComponent } from '../home/tdct-post/tdct-post-controller/tdct-post-controller.component';
import { TdctPingControllerComponent } from '../home/tdct-ping/tdct-ping-controller/tdct-ping-controller.component';
import { TdctAskControllerComponent } from '../home/tdct-ask/tdct-ask-controller/tdct-ask-controller.component';
import { TdctTaskControllerComponent } from '../home/tdct-task/tdct-task-controller/tdct-task-controller.component';
import { TdctMessageControllerComponent } from '../home/tdct-message/tdct-message-controller/tdct-message-controller.component';
import { TdctAskOptionComponent } from '../home/tdct-ask/tdct-ask-option/tdct-ask-option.component';
import { TdctAskEditorFormComponent } from '../home/tdct-ask/tdct-ask-editor-form/tdct-ask-editor-form.component';
import { TdctUserPromoterComponent } from '../home/tdct-user/tdct-user-promoter/tdct-user-promoter.component';
import { TdctUserPromoterControllerComponent } from '../home/tdct-user/tdct-user-promoter/tdct-user-promoter-controller/tdct-user-promoter-controller.component';
import { ImpEventListComponent } from '../home/imp-event/imp-event-list/imp-event-list.component';
import { ImpEventTicketOptionListComponent } from '../home/imp-event/imp-event-ticket-option-list/imp-event-ticket-option-list.component';
import { ImpTicketOptionOptionComponent } from '../home/imp-ticket/imp-ticket-option-option/imp-ticket-option-option.component';
import { ImpTicketOptionEditorFormComponent } from '../home/imp-ticket/imp-ticket-option-editor-form/imp-ticket-option-editor-form.component';
import { ImpEventTableOptionListComponent } from '../home/imp-event/imp-event-table-option-list/imp-event-table-option-list.component';
import { ImpTableOptionOptionComponent } from '../home/imp-table/imp-table-option-option/imp-table-option-option.component';
import { ImpTableOptionEditorFormComponent } from '../home/imp-table/imp-table-option-editor-form/imp-table-option-editor-form.component';
import { ImpTableBottleOptionComponent } from '../home/imp-table/imp-table-bottle-option/imp-table-bottle-option.component';
import { ImpTableBottleOptionListComponent } from '../home/imp-table/imp-table-bottle-option-list/imp-table-bottle-option-list.component';
import { ImpTableOptionComponent } from '../home/imp-table/imp-table-option/imp-table-option.component';
import { ImpBottleOptionOptionComponent } from '../home/imp-bottle/imp-bottle-option-option/imp-bottle-option-option.component';
import { ImpBottleOptionEditorFormComponent } from '../home/imp-bottle/imp-bottle-option-editor-form/imp-bottle-option-editor-form.component';
import { ImpCityCreatorFormComponent } from '../home/imp-city/imp-city-creator-form/imp-city-creator-form.component';
import { ImpCityEditorFormComponent } from '../home/imp-city/imp-city-editor-form/imp-city-editor-form.component';
import { ImpCityOptionComponent } from '../home/imp-city/imp-city-option/imp-city-option.component';
import { ImpCityListComponent } from '../home/imp-city/imp-city-list/imp-city-list.component';
import { ImpEventBottleOptionListComponent } from '../home/imp-event/imp-event-bottle-option-list/imp-event-bottle-option-list.component';
import { ImpEventBottleOptionComponent } from '../home/imp-event/imp-event-bottle-option/imp-event-bottle-option.component';
import { ImpEventStripeElementsFormComponent } from '../home/imp-event/imp-event-stripe-elements-form/imp-event-stripe-elements-form.component';
import { ImpTableBottleListComponent } from '../home/imp-table/imp-table-bottle-list/imp-table-bottle-list.component';
import { TdctUserPromoterEditorFormComponent } from '../home/tdct-user/tdct-user-promoter-editor-form/tdct-user-promoter-editor-form.component';
import { TdctUserPromoterOptionComponent } from '../home/tdct-user/tdct-user-promoter-option/tdct-user-promoter-option.component';
import { TdctUserPromoterAnalyticsComponent } from '../home/tdct-user/tdct-user-promoter/tdct-user-promoter-analytics/tdct-user-promoter-analytics.component';
import { TabsPage } from '../tabs/tabs.page';
import { ImpEventAnalyticsComponent } from '../home/imp-event/imp-event-analytics/imp-event-analytics.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { CheckoutReturnPageComponent } from '../home/checkout-return-page/checkout-return-page.component';
import { EventDetailsComponent } from '../home/event-details/event-details.component';
import { TdctUploadFormComponent } from '../home/tdct-upload/tdct-upload-form/tdct-upload-form.component';

@NgModule({
  declarations: [
    TdctAuthComponent,
    TdctUserComponent,
    TdctDeviceComponent,
    TdctUploadFormComponent,
    TdctUploadComponent,
    TdctPostComponent,
    TdctAskComponent,
    TdctTaskComponent,
    TdctPingComponent,
    TdctMessageComponent,
    TdctAdminComponent,
    ImpEventComponent,
    ImpCityComponent,
    ImpTicketComponent,
    ImpTableComponent,
    ImpTableDetailComponent,
    ImpBottleComponent,
    ImpVenueComponent,
    TdctAuthLoginFormComponent,
    TdctAuthSignupFormComponent,
    TdctAuthResetFormComponent,
    TdctUserCreatorFormComponent,
    TdctUserEditorFormComponent,
    TdctUserEmailFormComponent,
    TdctUserPasswordFormComponent,
    TdctAdminCoreEngineComponent,
    TdctAdminBrandEngineComponent,
    ImpEventControllerComponent,
    ImpCityControllerComponent,
    ImpTicketControllerComponent,
    ImpTableControllerComponent,
    ImpBottleControllerComponent,
    ImpVenueControllerComponent,
    ImpEventCreatorFormComponent,
    ImpEventOptionComponent,
    ImpEventEditorFormComponent,
    TdctUserOptionComponent,
    ImpOptionComponent,
    ImpThemeComponent,
    TdctUserPromoterCreatorFormComponent,
    TdctUserControllerComponent,
    TdctDeviceControllerComponent,
    TdctUploadControllerComponent,
    TdctPingControllerComponent,
    TdctPostControllerComponent,
    TdctAskControllerComponent,
    TdctTaskControllerComponent,
    TdctMessageControllerComponent,
    TdctAskOptionComponent,
    TdctAskEditorFormComponent,
    TdctUserPromoterComponent,
    TdctUserPromoterControllerComponent,
    ImpEventListComponent,
    ImpEventTicketOptionListComponent,
    ImpTicketOptionOptionComponent,
    ImpTicketOptionEditorFormComponent,
    ImpEventTableOptionListComponent,
    ImpTableOptionOptionComponent,
    ImpTableOptionEditorFormComponent,
    ImpTableBottleOptionComponent,
    ImpTableBottleOptionListComponent,
    ImpTableOptionComponent,
    ImpBottleOptionOptionComponent,
    ImpBottleOptionEditorFormComponent,
    ImpCityCreatorFormComponent,
    ImpCityEditorFormComponent,
    ImpCityOptionComponent,
    ImpCityListComponent,
    ImpEventBottleOptionListComponent,
    ImpEventBottleOptionComponent,
    ImpEventStripeElementsFormComponent,
    ImpTableBottleListComponent,
    TdctUserPromoterEditorFormComponent,
    TdctUserPromoterOptionComponent,
    TdctUserPromoterAnalyticsComponent,
    ImpEventAnalyticsComponent,
    TabsPage,
    CheckoutReturnPageComponent,
    EventDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    RouterModule,        
    ReactiveFormsModule,
    ImageCropperModule,
    NgxQRCodeModule,
    HttpClientModule,
    ClipboardModule
  ],
  exports: [
    TdctAuthComponent,
    TdctUploadFormComponent,
    TdctUserComponent,
    TdctDeviceComponent,
    TdctUploadComponent,
    TdctPostComponent,
    TdctAskComponent,
    TdctTaskComponent,
    TdctPingComponent,
    TdctMessageComponent,
    TdctAdminComponent,
    ImpEventComponent,
    ImpCityComponent,
    ImpTicketComponent,
    ImpTableComponent,
    ImpTableDetailComponent,
    ImpBottleComponent,
    ImpVenueComponent,
    TdctAuthLoginFormComponent,
    TdctAuthSignupFormComponent,
    TdctAuthResetFormComponent,
    TdctUserCreatorFormComponent,
    TdctUserEditorFormComponent,
    TdctUserEmailFormComponent,
    TdctUserPasswordFormComponent,
    TdctAdminCoreEngineComponent,
    TdctAdminBrandEngineComponent,
    ImpCityControllerComponent,
    ImpTicketControllerComponent,
    ImpTableControllerComponent,
    ImpBottleControllerComponent,
    ImpVenueControllerComponent,
    ImpEventCreatorFormComponent,
    ImpEventOptionComponent,
    ImpEventEditorFormComponent,
    TdctUserOptionComponent,
    ImpOptionComponent,
    ImpThemeComponent,
    TdctUserPromoterCreatorFormComponent,
    TdctUserControllerComponent,
    TdctDeviceControllerComponent,
    TdctUploadControllerComponent,
    TdctPingControllerComponent,
    TdctPostControllerComponent,
    TdctAskControllerComponent,
    TdctTaskControllerComponent,
    TdctMessageControllerComponent,
    TdctAskOptionComponent,
    TdctAskEditorFormComponent,
    TdctUserPromoterComponent,
    TdctUserPromoterControllerComponent,
    ImpEventListComponent,
    ImpEventTicketOptionListComponent,
    ImpTicketOptionOptionComponent,
    ImpTicketOptionEditorFormComponent,
    ImpEventTableOptionListComponent,
    ImpTableOptionOptionComponent,
    ImpTableOptionEditorFormComponent,
    ImpTableBottleOptionComponent,
    ImpTableBottleOptionListComponent,
    ImpTableOptionComponent,
    ImpBottleOptionOptionComponent,
    ImpBottleOptionEditorFormComponent,
    ImpCityCreatorFormComponent,
    ImpCityEditorFormComponent,
    ImpCityOptionComponent,
    ImpCityListComponent,
    ImpEventBottleOptionListComponent,
    ImpEventBottleOptionComponent,
    ImpEventStripeElementsFormComponent,
    ImpTableBottleListComponent,
    TdctUserPromoterEditorFormComponent,
    TdctUserPromoterOptionComponent,
    TdctUserPromoterAnalyticsComponent,
    ImpEventAnalyticsComponent,
    TabsPage,
    ImpEventControllerComponent,
    CheckoutReturnPageComponent,
    EventDetailsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
],
})
export class SharedModule { }
