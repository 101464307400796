import { Component, OnInit, Input,  } from '@angular/core';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { TdctAskService } from './tdct-ask.service';
import { DefaultAsk } from './tdct-ask.mocks';
import { Ask } from './tdct-ask.model';
import { PopoverController } from '@ionic/angular';
import { TdctAskOptionComponent } from './tdct-ask-option/tdct-ask-option.component';


@Component({
  selector: 'app-tdct-ask',
  templateUrl: './tdct-ask.component.html',
  styleUrls: ['./tdct-ask.component.scss'],
})
export class TdctAskComponent implements OnInit {

  @Input() ask$: Ask = DefaultAsk;

  constructor(
    public ask: TdctAskService,
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public popover: PopoverController
  ) { }

  ngOnInit() { }

  toggleAskOption(action: string) {
    this.auth.viewingAskOptionPopover = !this.auth.viewingAskOptionPopover;
    if (this.auth.viewingAskOptionPopover) {
      this.ask.set(this.ask$);
      switch (action) {
        case 'manage': {
          this.ask.managing = true;
          this.ask.sharing = false;
        }
                       break;
        default: {
          this.ask.managing = false;
          this.ask.sharing = true;
        }
      }
      this.presentPopover();
    }
  }

  async presentPopover() {
    this.auth.optionControl = await this.popover.create({
      component: TdctAskOptionComponent,
      showBackdrop: false,
      animated: true
    });
    this.auth.optionControl.onDidDismiss().then(() => {
      this.auth.viewingAskOptionPopover = false;
    });
    return await this.auth.optionControl.present();
  }

}
