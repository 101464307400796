import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { ImpTableService } from '../imp-table.service';
import { ImpBottleService } from '../../imp-bottle/imp-bottle.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import {AlertController} from '@ionic/angular';


@Component({
  selector: 'app-imp-table-option',
  templateUrl: './imp-table-option.component.html',
  styleUrls: ['./imp-table-option.component.scss'],
})
export class ImpTableOptionComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public table: ImpTableService,
    public bottle: ImpBottleService,
    public admin: TdctAdminService,
    public alertCtrl: AlertController
  ) { }

  ngOnInit() { }

  toggleTableBottleOptionCreatorForm(): void {
    this.auth.viewingEventOptionBottleCreatorForm = !this.auth.viewingEventOptionBottleCreatorForm;
    if (this.auth.viewingEventOptionBottleCreatorForm) {
      this.admin.dismissOptionControl();
      this.auth.viewingTableBottleOptionPopover = false;
    }
  }

}
