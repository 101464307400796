import { Injectable } from '@angular/core';
import { Ping } from './tdct-ping.model';
import { DefaultPing, MockPings } from './tdct-ping.mocks';

@Injectable({
  providedIn: 'root'
})
export class TdctPingService {

  $: Ping = DefaultPing;
  s$: Ping[] = MockPings;
  count: number = 0;
  
  constructor() { }
}
