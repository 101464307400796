import { Component, OnInit,  } from '@angular/core';


@Component({
  selector: 'app-tdct-upload',
  templateUrl: './tdct-upload.component.html',
  styleUrls: ['./tdct-upload.component.scss'],
})
export class TdctUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
