
import { Injectable } from '@angular/core';
import { Admin } from './tdct-admin.model';
import { DefaultAdmin } from './tdct-admin.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { User } from '../tdct-user/tdct-user.model';
import { JohnUser } from '../tdct-user/tdct-user.mocks';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class TdctAdminService {

  $: Admin = DefaultAdmin;
  user$: User = JohnUser;
  digitalPoints: number = 0;
  brandDigitalPoints: number = 0;
  coreDigitalPoints: number = 0;
  brandDigitalCount: number = 0;
  brandDigitalTotal: number = 0;
  coreDigitalCount: number = 0;
  coreDigitalTotal: number = 0;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public afa: AngularFireAuth,
  ) {
    this.sync();
  }

  async sync() {
    await this.afa.authState.subscribe((state) => {
      if (state) { this.setAdminUser(); }
    });
  }

  async setAdminUser() {
    let currentUser = await this.afa.currentUser;
    const user = (await this.user.get( currentUser.uid));
    if (user.id && user.roles.includes('admin')) {
      this.user$ = user;
      this.setBrandDigitTotals();
      this.setCoreDigitTotals();
    }
  }

  setBrandDigitTotals(): void {
    this.$.eventCount = this.user$.eventTotal;
    this.$.cityCount = this.user$.cityTotal;
    this.$.venueCount = this.user$.venueTotal;
    this.$.ticketCount = this.user$.ticketTotal;
    this.$.tableCount = this.user$.tableTotal;
    this.$.bottleCount = this.user$.bottleTotal;
  }

  setCoreDigitTotals(): void {
    this.$.userCount = this.user$.userTotal;
    this.$.deviceCount = this.user$.deviceTotal;
    this.$.uploadCount = this.user$.uploadTotal;
    this.$.postCount = this.user$.postTotal;
    this.$.askCount = this.user$.askTotal;
    this.$.taskCount = this.user$.taskTotal;
    this.$.pingCount = this.user$.pingTotal;
    this.$.messageCount = this.user$.messageTotal;
  }
l;tjrktj
  startEngine(): void {
    this.digitalPoints = 0;
    this.countBrandDigits();
    this.countCoreDigits();
    this.countEvents();
    this.countCities();
    this.countTickets();
    this.countTables();
    this.countBottles();
    this.countUsers();
  }

  startBrandEngine(): void {
    this.digitalPoints = this.digitalPoints - this.$.eventCount - this.$.cityCount - this.$.venueCount - this.$.ticketCount - this.$.tableCount - this.$.bottleCount;
    this.auth.viewingCoreEngine = false;
    this.auth.viewingBrandEngine = true;
    this.countBrandDigits();
    this.countEvents();
    this.countCities();
    this.countTickets();
    this.countTables();
    this.countBottles();
  }

  countBrandDigits(): void {
    this.brandDigitalCount = 0;
    this.brandDigitalTotal = 6;
    let interval = setInterval(() => {
      this.brandDigitalCount++;
      if (this.brandDigitalCount >= this.brandDigitalTotal) {
        clearInterval(interval);
        this.brandDigitalCount = this.brandDigitalTotal;
      }
    }, 100);
  }

  countEvents(): void {
    this.$.eventCount = 0;
    if (this.user$.eventTotal > 0) {
      let interval = setInterval(() => {
        this.incrementBrandDigitalPoints(1);
        this.$.eventCount++;
        if (this.$.eventCount >= this.user$.eventTotal) {
          clearInterval(interval);
          this.$.eventCount = this.user$.eventTotal;
        }
      }, 100);
    }
  }

  countCities(): void {
    this.$.cityCount = 0;
    if (this.user$.cityTotal > 0) {
      let interval = setInterval(() => {
        this.incrementBrandDigitalPoints(1);
        this.$.cityCount++;
        if (this.$.cityCount >= this.user$.cityTotal) {
          clearInterval(interval);
          this.$.cityCount = this.user$.cityTotal;
        }
      }, 100);
    }
  }

  countTickets(): void {
    this.$.ticketCount = 0;
    if (this.user$.ticketTotal > 0) {
      let interval = setInterval(() => {
        this.incrementBrandDigitalPoints(1);
        this.$.ticketCount++;
        if (this.$.ticketCount >= this.user$.ticketTotal) {
          clearInterval(interval);
          this.$.ticketCount = this.user$.ticketTotal;
        }
      }, 100);
    }
  }

  countTables(): void {
    this.$.tableCount = 0;
    if (this.user$.tableTotal > 0) {
      let interval = setInterval(() => {
        this.incrementBrandDigitalPoints(1);
        this.$.tableCount++;
        if (this.$.tableCount >= this.user$.tableTotal) {
          clearInterval(interval);
          this.$.tableCount = this.user$.tableTotal;
        }
      }, 100);
    }
  }

  countBottles(): void {
    this.$.bottleCount = 0;
    if (this.user$.bottleTotal > 0) {
      let interval = setInterval(() => {
        this.incrementBrandDigitalPoints(1);
        this.$.bottleCount++;
        if (this.$.bottleCount >= this.user$.bottleTotal) {
          clearInterval(interval);
          this.$.bottleCount = this.user$.bottleTotal;
        }
      }, 100);
    }
  }

  stopBrandEngine(): void {
    this.auth.viewingBrandEngine = false;
    this.$.controllingEvents = false;
    this.auth.viewingController = false;
    this.decrementDigitalPoints(this.brandDigitalPoints);
    this.brandDigitalPoints = 0;
    this.$.eventCount = 0;
    this.startEngine();
  }

  clearBrandEngine(): void {
    this.$.controllingEvents = false;
    this.$.controllingCities = false;
    this.$.controllingVenues = false;
    this.$.controllingTickets = false;
    this.$.controllingTables = false;
    this.$.controllingBottles = false;
  }

  startCoreEngine(): void {
    this.digitalPoints = this.digitalPoints - this.$.userCount;
    this.auth.viewingBrandEngine = false;
    this.auth.viewingCoreEngine = true;
    this.countCoreDigits();
    this.countUsers();
  }

  countCoreDigits(): void {
    this.coreDigitalCount = 0;
    this.coreDigitalTotal = 8;
    let interval = setInterval(() => {
      this.coreDigitalCount++;
      if (this.coreDigitalCount >= this.coreDigitalTotal) {
        clearInterval(interval);
        this.coreDigitalCount = this.coreDigitalTotal;
      }
    }, 100);
  }

  countUsers(): void {
    this.$.userCount = 0;
    let interval = setInterval(() => {
      this.incrementCoreDigitalPoints(1);
      this.$.userCount++;
      if (this.$.userCount >= this.user$.userTotal) {
        clearInterval(interval);
        this.$.userCount = this.user$.userTotal;
      }
    }, 100);
  }

  stopCoreEngine(): void {
    this.$.controllingUsers = false;
    this.auth.viewingCoreEngine = false;
    this.auth.viewingController = false;
    this.decrementDigitalPoints(this.coreDigitalPoints);
    this.coreDigitalPoints = 0;
    this.$.userCount = 0;
    this.startEngine();
  }

  stopEngine(): void {
    this.auth.viewingController = false;
    this.stopBrandEngine();
    this.stopCoreEngine();
  }

  incrementDigitalPoints(points: number): void {
    this.digitalPoints = this.digitalPoints + points;
  }

  decrementDigitalPoints(points: number): void {
    this.digitalPoints = this.digitalPoints - points;
  }

  incrementBrandDigitalPoints(points: number): void {
    this.brandDigitalPoints = this.brandDigitalPoints + points;
    this.incrementDigitalPoints(points);
  }

  incrementCoreDigitalPoints(points: number): void {
    this.coreDigitalPoints = this.coreDigitalPoints + points;
    this.incrementDigitalPoints(points);
  }

  dismissOptionControl() {
    this.auth.optionControl.dismiss();
  }

    load(): void {
    // Implement loading logic here, if any
    console.log('Admin Service is loading...');
  }
}
