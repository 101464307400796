import { Component, OnInit,  } from '@angular/core';
import { ImpCityService } from '../imp-city.service';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { City } from '../imp-city.model';
import * as moment from 'moment';


@Component({
  selector: 'app-imp-city-editor-form',
  templateUrl: './imp-city-editor-form.component.html',
  styleUrls: ['./imp-city-editor-form.component.scss'],
})
export class ImpCityEditorFormComponent implements OnInit {

  submitted: boolean = false;
  settings: Array<string> = [];
  customPopoverOptions: any = {
    header: 'Please select city type',
    message: 'One option per city'
  };

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public city: ImpCityService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.city.editing = false;
    this.auth.uploadingCityImage = false;
  }

  toggleCityImageChange(): void {
    this.auth.uploadingCityImage = !this.auth.uploadingCityImage;
  }

  async submit(cityForm) {
    this.submitted = true;
    this.stamp(cityForm);
    await this.editCity();
  }

  stamp(cityForm: City) {
    this.city.$.name = cityForm.name || this.city.$.name;
    this.city.$.description = cityForm.description || this.city.$.description;
    this.city.$.description = this.city.$.description.replace(/(\r\n\t|\n|\r\t)/gm, "");
  }

  async editCity() {
    try {
      this.updateTimestamp();
      await this.city.get(this.city.$.id);
      await this.city.doc.update({
        name: this.city.$.name,
        description: this.city.$.description,
        image: this.city.$.image,
        unixLastUpdateTimestamp: this.city.$.unixLastUpdateTimestamp,
        displayLastUpdateTimestamp: this.city.$.displayLastUpdateTimestamp,
      });
      this.auth.loading = false;
      this.city.editing = false;
    } catch (error) {
      this.auth.presentErrorAlert(error);
    }
  }

  updateTimestamp(): void {
    this.city.$.displayLastUpdateTimestamp = moment().format('YYYY-MM-DD');
    this.city.$.unixLastUpdateTimestamp = moment().unix();
  }

  clearForm(cityForm: City): void {
    cityForm.name = "";
    cityForm.description = "";
    this.submitted = false;
  }
}
