import { Injectable } from '@angular/core';
import { Option } from './imp-option.model';
import { DefaultOption, MockOptions } from './imp-option.mocks';
import { AngularFirestoreCollectionGroup, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { first } from 'rxjs/operators';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';

@Injectable({
  providedIn: 'root'
})
export class ImpOptionService {

  $: Option = DefaultOption;
  s$: Option[] = MockOptions;
  all$: Option[] = [];
  sHidden$: Option[] = [];
  search: string = "";
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  maxTableAttendance?: number; // Ensure this property is included
  noneFound: boolean = false;
  editing: boolean = false;
  doc: AngularFirestoreDocument<Option>;
  optionGroup: AngularFirestoreCollectionGroup<Option>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public afs: AngularFirestore
  ) { }

  async get(optionId: string): Promise<Option> {
    const optionPath = `events/${this.event.$.id}/options/${optionId}`
    this.doc = this.afs.doc<Option>(optionPath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  set(user: Option): void {
    this.$ = user;
    this.viewing = true;
    this.$ = this.auth.momentLastUpdatedFromNow(this.$);
    this.$ = this.auth.momentCreatedExactly(this.$);
  }

  async load(): Promise<Option[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.optionGroup.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$.map((option) => this.auth.momentLastUpdatedFromNow(option));
      this.set(this.s$[0]);
      this.total = this.s$.length;
    }
    this.loading = false;
    return this.s$;
  }

  async filter() {
    if (this.auth.viewingEventTicketOptions) {
      this.optionGroup = await this.afs.collectionGroup<Option>('options', ref => ref
        .where('eventId', '==', this.event.$.id)
        .where('settings', 'array-contains', 'ticket')
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    }
    if (this.auth.viewingEventTableOptions) {
      this.optionGroup = await this.afs.collectionGroup<Option>('options', ref => ref
        .where('eventId', '==', this.event.$.id)
        .where('settings', 'array-contains', 'table')
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    }
    if (this.auth.viewingEventBottleOptions) {
      this.optionGroup = await this.afs.collectionGroup<Option>('options', ref => ref
        .where('eventId', '==', this.event.$.id)
        .where('settings', 'array-contains', 'bottle')
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    }
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length == 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      let interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    if (this.all$.every((option: Option) => option.settings.includes('bottle'))) {
      this.s$ = this.all$.map((option: Option) => option);
    } else {
      let loadinOptions: Option[] = [];
      if (this.all$.length == 1) {
        loadinOptions = this.all$.splice(0, 1);
      } else if (this.all$.length == 2) {
        loadinOptions = this.all$.splice(0, 2);
      } else { loadinOptions = this.all$.splice(0, 3); }
      this.s$.push(...loadinOptions);
    }
  }
    
  // New method to get options for a specific event ID
  async getOptionsForEvent(eventId: string): Promise<Option[]> {
    try {
      const optionsCollection = this.afs.collection<Option>(`events/${eventId}/options`);
      const optionsSnapshot = await optionsCollection.valueChanges().pipe(first()).toPromise();
      return optionsSnapshot || [];
    } catch (error) {
      console.error("Error retrieving options for event: ", error);
      return [];
    }
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }
}
