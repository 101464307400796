import { Component, OnInit } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AlertController, PopoverController } from '@ionic/angular';
import { ImpEventService } from '../imp-event.service';
import { ImpEventCreatorFormComponent } from '../imp-event-creator-form/imp-event-creator-form.component';
import { ImpEventAnalyticsComponent } from '../imp-event-analytics/imp-event-analytics.component';
import { ImpEventTicketOptionComponent } from '../imp-event-option-ticket-creator-form/imp-event-option-ticket-creator-form.component';
import { ImpEventTableOptionComponent } from '../imp-event-option-table-creator-form/imp-event-option-table-creator-form.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-imp-event-option',
  templateUrl: './imp-event-option.component.html',
  styleUrls: ['./imp-event-option.component.scss'],
})
export class ImpEventOptionComponent implements OnInit {
  public eventData;
  eventFeatured = false;
  public userId: string;
    public isModalOpen = false;
    scannerSpecificLink = '';

  constructor(
    public event: ImpEventService,
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public alert: AlertController,
    public eventPopover: PopoverController,
    public afa: AngularFireAuth,
    public modalController: ModalController,
    private router: Router
  ) {}

  ngOnInit() {
    this.eventFeatured = this.event.$.tags.includes('featured');
    this.afa.authState.subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      }
    });
      console.log('current event' + this.event.currentEvent.name);
      console.log('current event id' + this.event.currentEvent.id);
  }

  editEvent() {
    console.log('[imp-event-option.component].editEvent');
    this.auth.editingEvent = true;
    this.presentPopover();
    }

    async presentPopover() {
        const popover = await this.eventPopover.create({
            component: ImpEventCreatorFormComponent,
            cssClass: 'event-popover-class',
            showBackdrop: false,
            animated: true,
            componentProps: {
                eventData: this.event.currentEvent // Pass the current event data here
            },
        });
        await popover.present();
    }
    async deleteEvent() {
        try {
            console.log('deleteEvent function started');
            this.event.loading = true;
            console.log('Loading state set to true');

            // Log the event ID
            const eventId = this.event.currentEvent.id;
            console.log(`Attempting to delete event with ID: ${eventId}`);

            const eventDocRef = this.event.afs.doc(`events/${eventId}`).ref;

            // Delete all associated subcollections (like 'options')
            await this.deleteSubcollections(eventDocRef, ['options']);

            // Delete the main event document
            await eventDocRef.delete();
            console.log('Document and its contents are fully deleted, including subcollections.');

            // Check if the document still exists (to verify deletion)
            const docSnapshot = await eventDocRef.get();
            if (!docSnapshot.exists) {
                console.log('Event document does not exist, deletion verified');
            } else {
                console.warn('Event document still exists after deletion attempt');
            }

            // Update admin details
            --this.admin.digitalPoints;
            console.log(`Admin digital points decreased: ${this.admin.digitalPoints}`);

            --this.admin.brandDigitalPoints;
            console.log(`Admin brand digital points decreased: ${this.admin.brandDigitalPoints}`);

            --this.admin.$.eventCount;
            console.log(`Admin event count decreased: ${this.admin.$.eventCount}`);

            this.admin.dismissOptionControl();
            console.log('Admin dismissed option control');

            // Display an alert to confirm the deletion to the user
            const alert = await this.alert.create({
                header: 'Event Deleted',
                message: 'This event and its associated data have been deleted.',
                buttons: ['OK'],
            });
            console.log('Alert created for event deletion');

            await alert.present();
            console.log('Alert presented to the user');

            // Set viewingEventOptionPopover to false
            this.auth.viewingEventOptionPopover = false;
            console.log('viewingEventOptionPopover set to false');

            // Reload the event list for the user
            this.event.load(this.userId);
            console.log(`Event reloaded for userId: ${this.userId}`);

            // Set loading state back to false
            this.event.loading = false;
            console.log('Loading state set to false');
        } catch (error) {
            console.error('Error deleting event and subcollections:', error);
        }
    }

    async deleteSubcollections(docRef, subcollectionNames) {
        for (const subcollection of subcollectionNames) {
            const subCollectionRef = docRef.collection(subcollection);
            const subCollectionSnapshot = await subCollectionRef.get();

            // Loop through and delete each document in the subcollection
            const deletionPromises = [];
            subCollectionSnapshot.forEach(doc => {
                deletionPromises.push(doc.ref.delete());
                console.log(`Deleted document from subcollection '${subcollection}': ${doc.id}`);
            });

            // Wait for all subcollection deletions to complete
            await Promise.all(deletionPromises);
        }
    }


async viewEventAnalytics() {
  // Check if another popup is active
  if (this.auth.viewingEventOptionPopover) {
    // Dismiss the current popup before showing the new one
    await this.admin.dismissOptionControl();
    this.auth.viewingEventOptionPopover = false;
  }

  // Create and present the full-screen modal
  const modal = await this.modalController.create({
    component: ImpEventAnalyticsComponent,  // The component to display in the modal
    cssClass: 'full-screen-modal',          // Custom CSS class to make the modal full-screen
    backdropDismiss: false,                 // Optional: Prevent closing by clicking on the backdrop
    animated: true,                         // Animation for modal presentation
    componentProps: {
      eventData: this.event.currentEvent,
      eventName: this.event.currentEvent.name,          // Pass the event name to the modal
      promoterId: this.event.currentEvent.promoterId, // Get the promoter ID from the current event
      eventId: this.event.currentEvent.id // Get the event ID from the current event
    }
  });

  await modal.present(); // Present the modal
}

    viewEventScanner() {

    var promoterId = this.event.currentEvent.promoterId;
    const eventId = this.event.currentEvent.id;
      console.log('Promoter ID ' + promoterId);
      console.log('Event ID ' + eventId);
      if (!promoterId)
            promoterId = this.userId;

      if (promoterId && eventId) {
          this.scannerSpecificLink = `scanner/promoter/${promoterId}/event/${eventId}`;
          this.router.navigate([this.scannerSpecificLink]);
          console.log(this.scannerSpecificLink);
      this.modalController.dismiss();
       this.admin.dismissOptionControl();
      this.auth.viewingEventOptionPopover = false;
    } else {
      this.alert
        .create({
          header: "Error",
          message:
            "Cannot navigate to the scanner. Please ensure that both the promoter ID and event ID are available.",
          buttons: ["OK"],
        })
        .then((alert) => alert.present());
    }
  }

  async toggleEventFeature() {
    this.event.loading = true;
    this.eventFeatured = !this.eventFeatured;
    const tags = this.eventFeatured ? ['featured'] : [];
    await this.event.afs.doc(`events/${this.event.$.id}`).update({ tags });
    this.event.loading = false;
  }

    async toggleEventTicketOptionForm() {
        const eventId = this.event.currentEvent.id;

        // Check if eventId is valid before proceeding
        if (!eventId) {
            console.error('Error: Event ID is undefined. Cannot proceed with ticket option form.');
            return;
        }

        const popover = await this.eventPopover.create({
            component: ImpEventTicketOptionComponent,
            showBackdrop: true,
            animated: true,
            componentProps: {
                eventId: eventId // Pass the eventId to the popover
            }
        });

        await popover.present();

        // Assuming you want to handle some UI state after presenting the popover
        this.admin.dismissOptionControl();
        this.auth.viewingEventOptionPopover = false;
    }

    async toggleEventTableOptionForm() {
        const eventId = this.event.currentEvent.id;

        // Check if eventId is valid before proceeding
        if (!eventId) {
            console.error('Error: Event ID is undefined. Cannot proceed with table option form.');
            return;
        }

        const popover = await this.eventPopover.create({
            component: ImpEventTableOptionComponent,
            showBackdrop: true,
            animated: true,
            componentProps: {
                eventId: eventId // Pass the eventId to the popover
            }
        });

        await popover.present();

        // Assuming you want to handle some UI state after presenting the popover
        this.admin.dismissOptionControl();
        this.auth.viewingEventOptionPopover = false;
    }


  openManageEventModal() {
  this.isModalOpen = true;
}

  toggleEventBottleOptionForm() {
    this.auth.viewingEventOptionBottleCreatorForm = !this.auth.viewingEventOptionBottleCreatorForm;
    if (this.auth.viewingEventOptionBottleCreatorForm) {
      this.admin.dismissOptionControl();
      this.auth.viewingEventOptionPopover = false;
    }
    }
    // Method to show the full-screen modal
    async presentEventOptionsModal() {
        const modal = await this.modalController.create({
            component: ImpEventOptionComponent, // Use this component for modal content
            cssClass: 'full-screen-modal',      // Custom CSS class for full-screen
            backdropDismiss: false,             // Disable closing by backdrop click
            swipeToClose: true,                 // Optional: Allow swipe to close
        });

        await modal.present();
    }

    // Example of a method to close the modal
    async closeModal() {
        await this.modalController.dismiss(); // Close the modal
    }
}