import versionConfig from './version';
import firebaseConfig from './firebase';
import stripeConfig from './stripe';
import scannerConfig from './scanner';
import driversLicenseConfig from './drivers-license';
import durationsConfig from './durations';
import contentfulConfig from './contentful';
import mediaConfig from './media';

export const environment = {
  production:     true,
  name:           "(PROD)",
  version:        versionConfig.production,
  stripe:         stripeConfig.production,
  firebase:       firebaseConfig.production,
  scanner:        scannerConfig,
  driversLicense: driversLicenseConfig,
  durations:      durationsConfig,
  contentful:     contentfulConfig.production,
  media:          mediaConfig,
};
