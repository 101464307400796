import { Component, OnInit, Input } from '@angular/core';
import { Table } from './imp-table.model';
import { DefaultTable } from './imp-table.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpTableService } from './imp-table.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { ModalController } from '@ionic/angular';
import { ImpTableDetailComponent } from './imp-table-detail/imp-table-detail.component';

@Component({
  selector: 'app-imp-table',
  templateUrl: './imp-table.component.html',
  styleUrls: ['./imp-table.component.scss'],
})
export class ImpTableComponent implements OnInit {

  @Input() table$: Table = DefaultTable;
  eventRoute: string = '';
  selectedTable: Table | null = null;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public table: ImpTableService,
    public admin: TdctAdminService,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  ngOnChanges() {
    this.eventRoute = `/event/${this.table$.eventId}`;
  }

  openTableModal(table: Table) {
    this.selectedTable = table;
    this.presentModal();
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: ImpTableDetailComponent,
      componentProps: { table: this.selectedTable }
    });
    await modal.present();
  }
}
