import { Ask } from './tdct-ask.model';
export const LoanAsk: Ask = {
    id: '1',
    name: 'Loan Ask',
    description: 'Can I borrow $100 until I get my next check?',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-05-07',
    unixDueTimestamp: 1557258735,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['loan'],
    complete: false
};
export const PayAsk: Ask = {
    id: '2',
    name: 'Pay Ask',
    description: 'Can you pay me for finishing the Dion 1 development?',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-05-07',
    unixDueTimestamp: 1557258735,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['pay'],
    complete: false
};
export const ApproveAsk: Ask = {
    id: '3',
    name: 'Approve Ask',
    description: 'Can you approve the Cannabliss Proposal?',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-05-07',
    unixDueTimestamp: 1557258735,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['approve'],
    complete: false
};
export const ReferAsk: Ask = {
    id: '4',
    name: 'Refer Ask',
    description: 'Can you refer me from my Dion 1 Development work?',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-05-07',
    unixDueTimestamp: 1557258735,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['refer'],
    complete: false
};
export const ConsultAsk: Ask = {
    id: '5',
    name: 'Consult Ask',
    description: 'Can you consult on my Spermy Idea?',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-05-07',
    unixDueTimestamp: 1557258735,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['consult'],
    complete: false
};
export const DateAsk: Ask = {
    id: '6',
    name: 'Date Ask',
    description: 'Can I take you to dinner?',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-05-07',
    unixDueTimestamp: 1557258735,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['date'],
    complete: false
};
export const MockAsks: Ask[] = [LoanAsk, PayAsk, ApproveAsk, ReferAsk, ConsultAsk, DateAsk];
export const DefaultAsk: Ask = {
    id: '',
    name: '',
    description: '',
    receiverEmail: '',
    userId: '',
    userName: '',
    userEmail: '',
    userPhoto: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    displayDueTimestamp: '',
    unixDueTimestamp: 0,
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    tags: [],
    complete: false
};
