export default {
    debug: true,
    timeout: 9000,
    whitelist: {
        video: [
          'video/mp4',
          'video/ogg',
          'video/webm',
        ],
        audio: [
          'audio/aac',
          'audio/aacp',
          'audio/mpeg',
          'audio/mp4',
          'audio/ogg',
          'audio/wav',
          'audio/webm',
          'audio/x-caf',
          'audio/flac',
        ],
        image: [
          'image/gif',
          'image/apng',
          'image/avif',
          'image/webp',
          'image/jpg',
          'image/jpeg',
          'image/webp',
          'image/bmp',
          'image/svg',
          'image/png',
        ],
    },
}
