import { Component, OnInit,  } from '@angular/core';
import { Auth } from '../../tdct-auth/tdct-auth.model';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { DefaultAuth } from '../../tdct-auth/tdct-auth.mocks';
import { AngularFireFunctions } from '@angular/fire/functions';


@Component({
  selector: 'app-tdct-user-creator-form',
  templateUrl: './tdct-user-creator-form.component.html',
  styleUrls: ['./tdct-user-creator-form.component.scss'],
})
export class TdctUserCreatorFormComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public aff: AngularFireFunctions
  ) { }

  ngOnInit() {
    this.auth.$ = DefaultAuth;
    this.clearUserCreatorForm();
  }

  clearUserCreatorForm(): void {
    this.auth.$.displayName = '';
    this.auth.$.email = '';
    this.auth.$.password = '';
  }

  submitFromPassword(userCreatorForm): void {
    this.auth.$.password = this.auth.$.password.slice(0, -1);
    this.submit(userCreatorForm);
  }

  async submit(userCreatorForm) {
    this.auth.loading = true;
    this.submitted = true;
    if (this.validUserCreation()) {
      try {
        await this.user.create(await this.authorizeUser(userCreatorForm));
        this.user.load();
        ++this.admin.digitalPoints;
        ++this.admin.coreDigitalPoints;
        ++this.admin.$.userCount;
        this.auth.viewingUserCreatorForm = false;
        this.auth.loading = false;
      } catch (error) {
        error = {
          code: 'Error',
          message: error.message
        };
        this.auth.presentErrorAlert(error.message);
        this.auth.viewingUserCreatorForm = false;
        this.auth.loading = false;
      }
    }
  }


  async authorizeUser(userCreatorForm): Promise<Auth> {
    this.auth.loading = true;
    const newAuth: Auth = {
      uid: '',
      displayName: userCreatorForm.displayName,
      email: userCreatorForm.email,
      photoURL: 'https://firebasestorage.googleapis.com/v0/b/tdct-production.appspot.com/o/default-profile.png?alt=media&token=556ff22b-26bf-465d-b50b-73457f2c073d',
      password: userCreatorForm.password,
    };
    const createAccount = this.aff.httpsCallable('createUserAccount');
    const createdAccount = await createAccount(newAuth).toPromise();
    newAuth.uid = createdAccount.uid;
    return newAuth;
  }

  validUserCreation(): boolean {
    return true;
  }
}
