import { Component, OnInit,  } from '@angular/core';
import { Auth } from '../../tdct-auth/tdct-auth.model';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AlertController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import * as firebase from 'firebase';


@Component({
  selector: 'app-tdct-user-password-form',
  templateUrl: './tdct-user-password-form.component.html',
  styleUrls: ['./tdct-user-password-form.component.scss'],
})
export class TdctUserPasswordFormComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() { }


  async submit(passwordForm) {
    this.submitted = true;
    if (this.validPassword()) {
      await this.updatePassword(passwordForm);
      this.auth.viewingUserPasswordForm = false;
      this.clearForm(passwordForm);
      this.displayPasswordUpdateConfirmation();
    }
  }

  validPassword(): boolean {
    return true;
  }

  async updatePassword(passwordForm) {
  }

  async displayPasswordUpdateConfirmation() {
    const alert = await this.alertCtrl.create({
      header: 'Password Updated',
      message: 'Your password has been updated.',
      buttons: ['OK']
    });
    await alert.present();
  }

  clearForm(passwordForm: Auth): void {
    passwordForm.password = '';
    this.submitted = false;
  }

}
