import { Component, OnInit } from '@angular/core';
import { Auth } from '../tdct-auth.model';
import { DefaultAuth } from '../tdct-auth.mocks';
import { TdctAuthService } from '../tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { AlertController } from '@ionic/angular';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-tdct-auth-reset-form',
  templateUrl: './tdct-auth-reset-form.component.html',
  styleUrls: ['./tdct-auth-reset-form.component.scss'],
})
export class TdctAuthResetFormComponent implements OnInit {

  resetForm: Auth = DefaultAuth;
  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public alert: AlertController
  ) { }

  ngOnInit() {
    this.auth.$ = DefaultAuth;
  }

  async submit(resetForm: NgForm) {
    this.submitted = true;
    if (resetForm.valid) {
      this.auth.loading = true;
      await this.reset(resetForm.value);
      this.clearForm();
    }
  }

  async reset(resetForm: Auth) {
    try {
      await this.auth.afa.sendPasswordResetEmail(resetForm.email);
      this.confirmPasswordResetEmailSentAlert(resetForm.email);
      this.auth.presentSuccessAlert('Password reset email sent!');
      this.auth.errorMessage = '';
    } catch (error) {
      this.auth.presentErrorAlert(error);
      this.auth.errorMessage = error.message;
      this.auth.successMessage = '';
    } finally {
      this.auth.loading = false;
    }
  }

  confirmPasswordResetEmailSentAlert(email: string): void {
    const message = {
      header: 'Password Reset',
      message: `An email has been sent to ${email} with a link to reset your password.`
    };
    this.presentAlert(message);
  }

  async presentAlert(message) {
    const alert = await this.alert.create({
      header: message.header,
      message: message.message,
      buttons: ['OK']
    });
    await alert.present();
  }

  clearForm() {
    this.resetForm.email = '';
    this.submitted = false;
  }
}
