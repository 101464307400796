import { Component, OnInit } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AlertController } from '@ionic/angular';
import { TdctUserPromoterService } from '../tdct-user-promoter/tdct-user-promoter.service';
import { TdctUserService } from '../tdct-user.service';
import { User } from '../tdct-user.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-tdct-user-promoter-option',
  templateUrl: './tdct-user-promoter-option.component.html',
  styleUrls: ['./tdct-user-promoter-option.component.scss'],
})
export class TdctUserPromoterOptionComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public admin: TdctAdminService,
    public alert: AlertController,
    public afs: AngularFirestore,
  ) { }

  ngOnInit() { }

  editUserPromoter(): void {
    this.promoter.editing = true;
    this.admin.dismissOptionControl();
    this.auth.viewingUserPromoterOptionPopover = false;
  }

  async deleteUserPromoter() {
    this.promoter.loading = true;
    const deletePromoterPromises = [];
    const promoterUser: User = await this.afs.doc<User>(`users/${this.promoter.$.userId}`).valueChanges().pipe(take(1)).toPromise();
    await this.promoter.afs.doc(`users/${this.promoter.$.userId}/promoters/${this.promoter.$.id}`).delete();
    deletePromoterPromises.push(this.updateUserPromoterProfileCount(promoterUser));
    await Promise.all(deletePromoterPromises);
    --this.admin.digitalPoints;
    this.admin.dismissOptionControl();
    const alert = await this.alert.create({
      header: 'User Promoter Deleted',
      message: 'This promoter has been deleted.',
      buttons: ['OK']
    });
    await alert.present();
    this.auth.viewingUserPromoterOptionPopover = false;
    await this.promoter.load();
  }

  async updateUserPromoterProfileCount(user: User): Promise<void> {
    --user.promoterProfileCount;
    if (user.promoterProfileCount === 0) {
      user.roles = user.roles.filter((role: string) => role !== 'promoter');
    }
    return this.afs.doc(`users/${user.id}`).update(user);
  }

  async demoteUserPromoter() {
    this.promoter.loading = true;
    const userId = this.promoter.$.userId; // Assuming userId is readily available in this.promoter
    if (!userId) {
      console.error('User ID is missing.');
      return;
    }
    await this.demotePromoter(userId);
  }

  async demotePromoter(userId: string) {
    const demotePromoterPromises = [];
    const promoterPath: string = `users/${userId}/promoters/${this.promoter.$.id}`;
    demotePromoterPromises.push(this.deletePromoterDocument(promoterPath));
    const askingUser: User = await this.user.get(userId);
    this.removePromoterRole(askingUser);
    demotePromoterPromises.push(this.updateUserPromoterDemote(userId, askingUser));
    await Promise.all(demotePromoterPromises);
    this.auth.viewingUserPromoterOptionPopover = false;
    this.admin.dismissOptionControl();
    const alert = await this.alert.create({
      header: 'User Demoted',
      message: 'The user has been demoted from promoter.',
      buttons: ['OK']
    });
    await alert.present();
    await this.promoter.load();
  }

  async deletePromoterDocument(promoterPath: string): Promise<void> {
    await this.afs.doc(promoterPath).delete();
  }

  removePromoterRole(user: User): void {
    const index = user.roles.indexOf('promoter');
    if (index !== -1) {
      user.roles.splice(index, 1);
    }
  }

  async updateUserPromoterDemote(userId: string, user: User): Promise<void> {
    user.promoterProfileCount--;
    await this.afs.doc(`users/${userId}`).update(user);
  }

  viewPromoterAnalytics() {
    this.auth.viewingPromoterAnalytics = true;
    this.admin.dismissOptionControl();
    this.auth.viewingUserPromoterOptionPopover = false;
  }
}
