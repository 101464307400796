import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { Option } from '../../imp-option/imp-option.model';
import { DefaultOption } from '../../imp-option/imp-option.mocks';
import { AngularFirestore, AngularFirestoreCollectionGroup } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ImpTicketService } from '../../imp-ticket/imp-ticket.service';
import { PopoverController } from '@ionic/angular';
import { ImpTicketOptionOptionComponent } from '../../imp-ticket/imp-ticket-option-option/imp-ticket-option-option.component';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { ImpTimingService } from '../../imp-timing.service';
import { Promoter } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.model';
import moment from 'moment';

@Component({
    selector: 'app-imp-event-option-ticket-creator-form',
    templateUrl: './imp-event-option-ticket-creator-form.component.html',
    styleUrls: ['./imp-event-option-ticket-creator-form.component.scss'],
})
export class ImpEventTicketOptionComponent implements OnInit {

    @Input() eventId: string; 
    @Input() option$: Option = DefaultOption;
    ticketOptionHidden: boolean = false;
    isFollowingPromoter: boolean = false;
    public justClicked: boolean;
    promoterCol: AngularFirestoreCollectionGroup<Promoter>;
    submitted = false;

    constructor(
        public theme: ImpThemeService,
        public auth: TdctAuthService,
        public user: TdctUserService,
        public promoter: TdctUserPromoterService,
        public event: ImpEventService,
        public ticket: ImpTicketService,
        public option: ImpOptionService,
        public admin: TdctAdminService,
        public afs: AngularFirestore,
        public aff: AngularFireFunctions,
        public popover: PopoverController,
        public timing: ImpTimingService,
        private cdRef: ChangeDetectorRef
    ) {
        // Initialize the justClicked flag and check if the user is following the event promoter
        this.justClicked = true;
        this.isUserFollowingEventPromoter();
    }

    async ngOnInit(): Promise<void> {
        console.log('[ngOnInit] ImpEventTicketOptionComponent initialized.');
        this.markHiddenTicketOption();
        this.resetFormValues();
    }

    async submit(eventOptionTicketForm) {
        this.submitted = true;
    
        if (!this.validEventOptionTicketForm()) {
            return; 
        }
    
        try {
            this.auth.loading = true;
    
            await this.createTicketOption();
            console.log('Ticket option successfully created.');
    
            await this.dismissModal();
            this.resetFormValues();
        } catch (error) {
            console.error('Error during ticket option creation:', error);
            this.auth.presentErrorAlert({
                code: 'Error',
                message: 'Error creating event ticket option. Please try again.',
            });
        } finally {
            this.auth.loading = false;
        }
    }
    async createTicketOption() {
        if (!this.eventId) {
            console.error('Cannot create ticket option without a valid event ID.');
            return;
        }

        const ticketOption = this.option.$;
        ticketOption.eventId = this.eventId; 
        ticketOption.id = this.afs.createId();

        console.log('Creating Ticket Option with ID:', ticketOption.id);

        this.stampTime(ticketOption);
        this.stampUserPromoter(ticketOption);
        this.stampEvent(ticketOption);

        ticketOption.settings = ['ticket'];
        ticketOption.digits = ['event'];
        ticketOption.tablesAvailable = ticketOption.tablesAvailable || 0;

        const eventTicketOptionPath = `events/${this.eventId}/options/${ticketOption.id}`;
        console.log('Saving Ticket Option to Firestore at Path:', eventTicketOptionPath);

        try {
            await this.afs.doc(eventTicketOptionPath).set(ticketOption);
            console.log('Ticket Option Successfully Saved to Firestore:', ticketOption);
            this.event.$.ticketOptionCount++;
        } catch (error) {
            console.error('Error saving ticket option to Firestore:', error);
            throw error; 
        }
    }
    resetFormValues() {
        this.option.$ = { ...DefaultOption }; 
        this.submitted = false; 
        console.log('Form values have been reset.');
    }
    async dismissModal() {
        try {
          await this.popover.dismiss();
            console.log('Modal Dismissed');
        } catch (error) {
            console.error('Error dismissing modal:', error);
        }
    }
    validEventOptionTicketForm(): boolean {
        const { price, name, ticketsAvailable, description } = this.option.$;

        const isValid = (
            typeof price === 'number' && price > 0 &&
            typeof name === 'string' && name.trim().length > 0 &&
            typeof ticketsAvailable === 'number' && ticketsAvailable > 0 &&
            typeof description === 'string' && description.trim().length > 0
        );

        console.log('Form Validation Result:', isValid, this.option.$);
        return isValid;
    }

    stampTime(ticketOption) {
        ticketOption.displayTimestamp = moment().format('YYYY-MM-DD');
        ticketOption.displayLastUpdateTimestamp = ticketOption.displayTimestamp;
        ticketOption.unixTimestamp = moment().unix();
        ticketOption.unixLastUpdateTimestamp = ticketOption.unixTimestamp;

        console.log('Time Stamped on Ticket Option:', {
            displayTimestamp: ticketOption.displayTimestamp,
            unixTimestamp: ticketOption.unixTimestamp
        });
    }

    stampUserPromoter(ticketOption) {
        const user = this.user.$;
        Object.assign(ticketOption, {
            userId: user.id,
            userEmail: user.email,
            userName: user.name,
            userPhoto: user.photo,
        });

        console.log('User Promoter Stamped on Ticket Option:', {
            userId: user.id,
            userEmail: user.email,
            userName: user.name
        });
    }

    stampEvent(ticketOption) {
        if (!this.event || !this.event.$) {
            console.error('Error: eventService or event data is undefined. Cannot proceed with stamping event data.');
            return;
        }

        const event = this.event.$;

        console.log('Stamping Event Data: Current Event State:', {
            eventId: this.eventId,
            eventName: event.name,
            eventImage: event.image,
            eventDescription: event.description,
        });

        Object.assign(ticketOption, {
            eventName: event.name,
            eventImage: event.image,
            eventDescription: event.description,
        });

        console.log('Ticket Option After Stamping Event Data:', ticketOption);
    }

    ngOnChanges() {
        console.log('[ngOnChanges] Change detected in option data. Updating ticket option visibility.');
        this.markHiddenTicketOption();
    }

    markHiddenTicketOption(): void {
        console.log('[markHiddenTicketOption] Checking if ticket option should be hidden.');
        if (this.option.sHidden$.includes(this.option$)) {
            console.log('[markHiddenTicketOption] Ticket option is hidden.');
            this.ticketOptionHidden = true;
        } else {
            console.log('[markHiddenTicketOption] Ticket option is visible.');
            this.ticketOptionHidden = false;
        }
    }

    async toggleTicketOptionHide() {
        console.log('[toggleTicketOptionHide] Toggling ticket option visibility.');
        if (this.auth.viewingEventStripeElementsForm) {
            console.log('[toggleTicketOptionHide] Hiding Stripe Elements Form.');
            this.auth.viewingEventStripeElementsForm = false;
        } else {
            if (!this.option.sHidden$.includes(this.option$)) {
                console.log('[toggleTicketOptionHide] Hiding the ticket option.');
                this.option.sHidden$.push(this.option$);
            } else {
                console.log('[toggleTicketOptionHide] Showing the ticket option.');
                this.option.sHidden$ = this.option.sHidden$.filter(event => event !== this.option$);
            }
            this.markHiddenTicketOption();
            if (!this.ticketOptionHidden) {
                console.log('[toggleTicketOptionHide] Reloading ticket option because it is now visible.');
                await this.reloadTicketOption();
            }
        }
    }

    async reloadTicketOption() {
        console.log('[reloadTicketOption] Reloading ticket option.');
        this.option.loading = true;
        try {
            this.option$ = await this.option.get(this.option$.id);
            console.log('[reloadTicketOption] Successfully reloaded ticket option:', this.option$);
            this.option$ = this.auth.momentLastUpdatedFromNow(this.option$);
        } catch (error) {
            console.error('[reloadTicketOption] Error reloading ticket option:', error);
        } finally {
            this.option.loading = false;
        }
    }

    toggleEventStripeElementsForm(): void {
        console.log('[toggleEventStripeElementsForm] Toggling Stripe Elements Form visibility.');
        this.justClicked = true;
        this.auth.viewingEventStripeElementsForm = !this.auth.viewingEventStripeElementsForm;
        if (this.auth.viewingEventStripeElementsForm) {
            console.log('[toggleEventStripeElementsForm] Stripe Elements Form is now visible.');
            this.option.set(this.option$);
        } else {
            console.log('[toggleEventStripeElementsForm] Stripe Elements Form is now hidden.');
        }
    }

    toggleTicketOptionManager(): void {
        console.log('[toggleTicketOptionManager] Toggling Ticket Option Manager visibility.');
        this.auth.viewingEventTicketOptionPopover = !this.auth.viewingEventTicketOptionPopover;
        if (this.auth.viewingEventTicketOptionPopover) {
            console.log('[toggleTicketOptionManager] Ticket Option Manager is now visible.');
            this.option.set(this.option$);
            this.presentPopover();
        } else {
            console.log('[toggleTicketOptionManager] Ticket Option Manager is now hidden.');
        }
    }

    async presentPopover() {
        console.log('[presentPopover] Presenting ticket option popover.');
        try {
            this.auth.optionControl = await this.popover.create({
                component: ImpTicketOptionOptionComponent,
                showBackdrop: false,
                animated: true
            });

            this.auth.optionControl.onDidDismiss().then(() => {
                console.log('[presentPopover] Popover dismissed.');
                this.auth.viewingEventTicketOptionPopover = false;
            });

            return await this.auth.optionControl.present();
        } catch (error) {
            console.error('[presentPopover] Error presenting popover:', error);
        }
    }

    routeLogin() {
        console.log('[routeLogin] Routing to login page.');
        this.auth.signOut();
        this.theme.tab.selected = 'moreTab';
        this.auth.viewing = true;
        this.auth.toggleLoginForm();
    }

    async isUserFollowingEventPromoter() {
        this.isFollowingPromoter = false;
        console.log('[isUserFollowingEventPromoter] Checking if the user is following the event promoter.');
        console.log('[isUserFollowingEventPromoter] User ID:', this.user.$.id);

        if (this.user.$.id === '') {
            console.log('[isUserFollowingEventPromoter] User ID is empty, not following.');
            this.isFollowingPromoter = false;
            this.justClicked = false;
            return;
        }

        try {
            this.promoterCol = await this.afs.collectionGroup<Promoter>('promoters', ref => ref
                .where('id', '==', this.event.$.promoterId)
                .orderBy('displayTimestamp', 'asc')
            );

            await this.promoterCol.get().toPromise().then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    console.log('[isUserFollowingEventPromoter] Found promoter document:', doc.id, doc.data());
                    const data = doc.data();
                    if (data.followers.includes(this.user.$.id) || data.userId === this.user.$.id) {
                        console.log('[isUserFollowingEventPromoter] User is a follower of or is the promoter.');
                        this.isFollowingPromoter = true;
                    }
                });
            });
        } catch (error) {
            console.error('[isUserFollowingEventPromoter] Error checking promoter followers:', error);
        }

        this.justClicked = false;
    }
}
