import { Component, OnInit } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event.service';

@Component({
  selector: 'app-imp-event-list',
  templateUrl: './imp-event-list.component.html',
  styleUrls: ['./imp-event-list.component.scss'],
})
export class ImpEventListComponent implements OnInit {
  hasEvents: boolean = false; // Flag to indicate if there are events to display

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public admin: TdctAdminService
  ) {}

  ngOnInit(): void {
    // Check if there are events to display
    this.hasEvents = this.event.s$.length > 0;
    //this.toggleUserPromoterEventCreatorForm();
  }

  togglePromoterEvents(): void {
    this.auth.viewingPromoterEvents = !this.auth.viewingPromoterEvents;
  }

  toggleUserPromoterEventCreatorForm(): void {
    this.auth.editingEvent = false;
    this.auth.viewingUserPromoterEventCreatorForm = !this.auth.viewingUserPromoterEventCreatorForm;
  }

  toggleEventTicketOptions(): void {
    if (this.auth.viewingEventTicketOptionPopover) {
      this.auth.viewingEventTicketOptionPopover = false;
    }
    this.auth.viewingEventTicketOptions = !this.auth.viewingEventTicketOptions;
    if (!this.auth.viewingEventTicketOptions) {
      this.auth.viewingEventOptionTicketForm = false;
      this.auth.viewingTicketOptionEditorForm = false;
    }
  }

  toggleEventTableOptions(): void {
    if (this.auth.viewingEventTableOptionPopover) {
      this.auth.viewingEventTableOptionPopover = false;
    }
    this.auth.viewingEventTableOptions = !this.auth.viewingEventTableOptions;
    if (!this.auth.viewingEventTableOptions) {
      this.auth.viewingEventOptionTableForm = false;
      this.auth.viewingTableOptionEditorForm = false;
    }
  }
}
